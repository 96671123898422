import Cookies from "js-cookie";
import { CART } from "../../const/ApiConst";
import { INSTANCE } from "../../const/Header";

export const createCart = async (cartData) => {
    const response = await INSTANCE.put(CART, cartData);
    return response.data;
};

export const getCart = async () => {
    const data = {
        custId: JSON.parse(Cookies.get('udtl')).uid
    }
    const response = await INSTANCE.post(CART, data)
    return response.data
};

export const removeFromCart = async (cartId) => {
    const response = await INSTANCE.post(`${CART}/delete`, cartId)
    return response.data
}

export const clearCart = async (custID) => {
    const response = await INSTANCE.post(`${CART}/clear-cart`, custID)
    return response.data
}

const cartService = {
    createCart,
    getCart,
    removeFromCart,
    clearCart
}

export default cartService;