import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import trendingService from "./TendingService"

const initialState = {
    trendingItems: [],
    isLoading: false,
    isError: false,
    isSuccess: false
}


export const getTrending = createAsyncThunk(
    "trending/getTrending",
    async (thunkAPI) => {
        try {
            return await trendingService.getTrending()
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

 


export const trendingSlice = createSlice({
    name: "trending",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTrending.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getTrending.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.trendingItems = action.payload
            })
            .addCase(getTrending.rejected, (state) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })
    }
})
export default trendingSlice.reducer