import { PRODUCT } from "../../const/ApiConst"
import { INSTANCE } from "../../const/Header"

export const getTrending = async (productData) => {
    const response = await INSTANCE.post(`${PRODUCT}/trending`, productData)
    return response.data
}


const trendingService = {
    getTrending
}

export default trendingService