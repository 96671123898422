import React from 'react'

function TermsandConditions() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
});
  return (
    <div className='container p-5 animation'>
      <h1>Website Terms and Conditions</h1>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing and using this website, you agree to be bound by these Terms and Conditions,
        all applicable laws, and regulations, and you agree that you are responsible for compliance
        with any applicable local laws. If you do not agree with any of these terms, you are
        prohibited from using or accessing this site.
      </p>

      <h2>2. Use License</h2>
      <p>
        Permission is granted to temporarily download one copy of the materials (information or software)
        on this website for personal, non-commercial transitory viewing only. This is the grant of a
        license, not a transfer of title, and under this license, you may not:
        <ul>
          <li>Modify or copy the materials;</li>
          <li>Use the materials for any commercial purpose or for any public display (commercial or non-commercial);</li>
          <li>Attempt to decompile or reverse engineer any software contained on this website;</li>
          <li>Remove any copyright or other proprietary notations from the materials; or</li>
          <li>Transfer the materials to another person or "mirror" the materials on any other server.</li>
        </ul>
        This license shall automatically terminate if you violate any of these restrictions and may be terminated
        by the website owner at any time.
      </p>

      <h2>3. Disclaimer</h2>
      <p>
        The materials on this website are provided on an 'as is' basis. The website owner makes no warranties,
        expressed or implied, and hereby disclaims and negates all other warranties including, without limitation,
        implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement
        of intellectual property or other violation of rights.
      </p>

      <h2>4. Limitations</h2>
      <p>
        In no event shall the website owner be liable for any damages (including, without limitation, damages
        for loss of data or profit, or due to business interruption) arising out of the use or inability to use
        the materials on this website, even if the website owner or an authorized representative has been notified
        orally or in writing of the possibility of such damage.
      </p>

      <h2>5. Revisions and Errata</h2>
      <p>
        The materials appearing on this website may include technical, typographical, or photographic errors.
        The website owner does not warrant that any of the materials on its website are accurate, complete,
        or current.
      </p>

      <h2>6. Links</h2>
      <p>
        The website owner has not reviewed all of the sites linked to its website and is not responsible for
        the contents of any such linked site. The inclusion of any link does not imply endorsement by the website
        owner of the site. Use of any such linked website is at the user's own risk.
      </p>

      <h2>7. Modifications to Terms and Conditions</h2>
      <p>
        The website owner may revise these terms of service at any time without notice. By using this website,
        you are agreeing to be bound by the current version of these Terms and Conditions.
      </p>

      <h2>8. Governing Law</h2>
      <p>
        These terms and conditions are governed by and construed in accordance with the laws of [Your Country],
        and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
      </p>

    </div>
  )
}

export default TermsandConditions