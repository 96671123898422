import React from 'react'

function Privacyandpolicy() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
});
  return (
    <div className='container p-5 animation'>
      <h1>Privacy Policy</h1>
      <p>
        [Your website/company name] is committed to respecting your privacy and protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you visit our website.
      </p>

      <h2>Information We Collect</h2>
      <p>
        [Explain what types of information you collect from users, such as personal data (e.g., name, email, address), non-personal information (e.g., IP address, browser type), and any other relevant data. Also, mention if you collect data from third-party services or cookies.]
      </p>

      <h2>How We Use Your Information</h2>
      <p>
        [Describe the purposes for which you use the collected information. For example, providing services, improving the website, sending newsletters, etc.]
      </p>

      <h2>Sharing Your Information</h2>
      <p>
        [Explain if and when you share users' information with third parties and the reasons for doing so. Also, mention if you transfer data internationally.]
      </p>

      <h2>Security</h2>
      <p>
        [Describe the security measures you take to protect users' data from unauthorized access, loss, or disclosure.]
      </p>

      <h2>Cookies and Similar Technologies</h2>
      <p>
        [Explain what cookies and similar technologies are used on your website, their purpose, and how users can manage or disable them. Provide a link to your Cookies Policy if you have one.]
      </p>

      <h2>Links to Other Websites</h2>
      <p>
        [If you have links to external websites, mention that you are not responsible for their privacy practices and encourage users to review their respective privacy policies.]
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        [State that you may update the privacy policy from time to time and provide the date of the last update.]
      </p>

      <h2>Contact Us</h2>
      <p>
        [Provide contact information (e.g., email address) for users to reach out if they have questions or concerns regarding their privacy.]
      </p>
    </div>
  )
}

export default Privacyandpolicy