import { configureStore } from '@reduxjs/toolkit'
import productReducer from '../store/product/ProductSlice'
import categoryReducer from '../store/category/CategorySlice'
import cartReducer from '../store/cart/CartSlice'
import addressReducer from '../store/address/AddressSlice'
import loginFormReducer from '../store/loginState'
import cartOpenReducer from '../store/CloseCartState'
import newArrivalReducer from '../store/newArrivals/NewArrivalSlice'
import trendingReducer from '../store/trending/TrendingSlice'
import featuredReducer from '../store/featured/FeaturedSlice'
import filterReducer from '../store/filter/FilterSlice'
import sizeReducer from '../store/size/SizeSlice'
import orderReducer from '../store/orders/OrderSlice' 

export const store = configureStore({
    reducer: {
        product: productReducer,
        categories: categoryReducer,
        cart: cartReducer,
        loginFormReducer: loginFormReducer,
        cartOpenReducer: cartOpenReducer,
        address: addressReducer,
        newArrivals: newArrivalReducer,
        trending: trendingReducer,
        featured: featuredReducer,
        filter: filterReducer,
        size: sizeReducer,
        orders: orderReducer,
    }
})