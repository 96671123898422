import React from 'react'

function Shippingdelivery() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
  return (
    <div>
      <div className="container p-5 animation">
        <h1 className="text-center">Shipping and Delivery Details for MilyOnline.in</h1>


        <h4>Thank you for choosing our services!</h4>
        <p>We are dedicated to providing you with a seamless and efficient shipping and delivery process. Below are the shipping and delivery details for your order:</p>

        <h4>Order Processing Time:</h4>
        <p>Once your order is placed, please allow 1-3 business days for order processing before shipment.</p>

        <h4>Shipping Options:</h4>
        <p>We offer various shipping options to suit your needs:</p>
        <ul>
          <li>Standard Shipping: Estimated delivery time within 5-7 business days.</li>
          {/* <li>Express Shipping: Estimated delivery time within 2-3 business days.</li> */}
          <li>International Shipping: Delivery times vary depending on the destination country.</li>
        </ul>

        <h4>Shipping Rates:</h4>
        <p>Shipping rates are calculated based on the weight, size, and destination of the package. You can view the applicable shipping charges during the checkout process.</p>


        <h4>Tracking Your Order:</h4>
        <p>After your order has been dispatched, we will provide you with a tracking number via email. You can use this tracking number to monitor the status and location of your package.</p>

        <h4>Delivery Partners:</h4>
        <p>We partner with reliable shipping carriers to ensure the safe and timely delivery of your order.</p>

        <h4>International Shipping:</h4>
        <p>For international orders, customs and import duties may apply depending on the destination country. These charges are the responsibility of the recipient and will be collected upon delivery. Please check with your local customs office for more information.</p>

        <h4>Delivery Address:</h4>
        <p>Please provide a valid and complete delivery address to avoid any delays or complications in the delivery process.</p>

        <h4>Delivery Confirmation:</h4>
        <p>Once your order is successfully delivered, you will receive a confirmation email. If you have any concerns about the delivery, please contact our customer support team.</p>

        <h4>Undeliverable Packages:</h4>
        <p>In case of an unsuccessful delivery attempt due to an incorrect address or any other reason, our shipping partner will make multiple attempts before returning the package to us. Additional shipping charges may apply if you request a re-shipment.</p>

        <p>If you have any specific shipping preferences or questions regarding your order's delivery, please feel free to reach out to our customer support team. We are here to assist you in every way possible to ensure a smooth and satisfying shopping experience.</p>

        <p>Thank you for shopping with us! We appreciate your business and look forward to serving you.</p>
      </div>
    </div>
  )
}

export default Shippingdelivery