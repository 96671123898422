import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearCart, createCartSlice, delteCartItem, getCart } from '../../store/cart/CartSlice';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { INSTANCE } from '../../const/Header';
import { PLACE_ORDER } from '../../const/ApiConst';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, Input } from 'antd';
import { useFormik } from 'formik';
import { addAddress, getAddress, updateAddress } from '../../store/address/AddressSlice';
import { Col, Form, Row } from 'react-bootstrap';
import './checkout.css'
import { MdOutlineDone } from 'react-icons/md'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

function Checkout() {


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: Adds smooth scrolling animation
        });
    }, [])

    const dispatch = useDispatch()
    const { cart } = useSelector((state) => state?.cart)


    const Navigate = useNavigate()
    useEffect(() => {
        dispatch(getCart())
    }, [])
    var subTotal = 0;

    const [isLoading, setIsLoading] = useState(false)


    const formik = useFormik({
        initialValues: {
            cust_id: "",
            name: '',
            contactNo: '',
            flat: '',
            area: '',
            landmark: '',
            town: '',
            pincode: '',
            state: '',
        },
        onSubmit: (values) => {
            values.cust_id = JSON.parse(Cookies.get("udtl")).uid
            dispatch(addAddress(values)); // Dispatch the addAddress action with the form values
            formik.resetForm();
            dispatch(getAddress({ custId: JSON.parse(Cookies.get("udtl")).uid }))
            setShowAddAddressForm(false)
        },
    });

    const updateformik = useFormik({
        initialValues: {
            addressId: "",
            cust_id: "143",
            name: '',
            contactno: '',
            flat: '',
            area: '',
            landmark: '',
            town: '',
            pincode: '',
            state: '',
        },
        onSubmit: (values) => {
            values.cust_id = JSON.parse(Cookies.get("udtl")).uid
            dispatch(updateAddress(values)); // Dispatch the addAddress action with the form values
            formik.resetForm();
            dispatch(getAddress({ custId: JSON.parse(Cookies.get("udtl")).uid }))
            setShowEditAddressForm(false)

        },
    });

    const { adresss } = useSelector((state) => state?.address)
    console.log(adresss);

    const [addressId, setAddressId] = useState(adresss[0]?._id)

    const makeOrder = async () => {
        setIsLoading(true)
        const data = {
            addressId,
            custId: JSON.parse(Cookies.get("udtl")).uid,
            cart
        }
        await INSTANCE.post(PLACE_ORDER, data)
            .then((response) => {
                Navigate("/success")
                dispatch(clearCart({ custId: JSON.parse(Cookies.get("udtl")).uid }))
            })
            .catch(err => console.log(err))
    }


    useEffect(() => {
        dispatch(getAddress({ custId: JSON.parse(Cookies.get("udtl")).uid }))
    }, [])

    const selectedStyle = {
        position: 'absolute',
        top: '13px',
        left: '-15px',
        background: 'black',
        color: 'white',
        padding: '0px 18px',
        fontSize: '12px',
        transform: 'rotate(-45deg)',
        clipPath: 'polygon(77 % 0, 100 % 57 %, 0 % 52 %, 21 % 0)',
        height: '40px',
        width: '100px',
        textAlign: 'center',
        lineHeight: '20px',
    }

    const [deliveryAddressToggle, setDeliveryAddressToggle] = useState(true)
    const [orderSummaryToggle, setOrderSummaryToggle] = useState(false)
    const [paymentToggle, setPaymentToggle] = useState(false)
    const [showEditAddressForm, setShowEditAddressForm] = useState(false)

    const openDeliveryAddress = () => {
        setDeliveryAddressToggle(true)
        setOrderSummaryToggle(false)
        setPaymentToggle(false)
    }

    const openOrderSummary = () => {
        setDeliveryAddressToggle(false)
        setOrderSummaryToggle(true)
        setPaymentToggle(false)
    }

    const openPaymentSection = () => {
        setDeliveryAddressToggle(false)
        setOrderSummaryToggle(false)
        setPaymentToggle(true)
    }

    const [showAddAddressForm, setShowAddAddressForm] = useState(false)

    const loadAddNewAddressForm = () => {
        return (

            <Form action='' onSubmit={formik.handleSubmit} className='checkOutAddressForm'>
                <Input
                    onChange={formik.handleChange("name")}
                    onBlur={formik.handleBlur("name")}
                    placeholder='Name'
                    value={formik.values.name} />
                <Input
                    onChange={formik.handleChange("contactno")}
                    onBlur={formik.handleBlur("contactno")}
                    placeholder='Contact No'
                    value={formik.values.contactno} />
                <Input
                    onChange={formik.handleChange("flat")}
                    onBlur={formik.handleBlur("flat")}
                    placeholder='Flat/Apartment/House'
                    value={formik.values.flat} />
                <Input
                    onChange={formik.handleChange("area")}
                    onBlur={formik.handleBlur("area")}
                    placeholder='Area/Street'
                    value={formik.values.area} />
                <Input
                    onChange={formik.handleChange("landmark")}
                    onBlur={formik.handleBlur("landmark")}
                    placeholder='Landmark'
                    value={formik.values.landmark} />
                <Input
                    onChange={formik.handleChange("town")}
                    onBlur={formik.handleBlur("town")}
                    placeholder='Town'
                    value={formik.values.town} />
                <Input
                    onChange={formik.handleChange("pincode")}
                    onBlur={formik.handleBlur("pincode")}
                    placeholder='PIN Code'
                    value={formik.values.pincode} />
                <Input
                    onChange={formik.handleChange("state")}
                    onBlur={formik.handleBlur("state")}
                    placeholder='State'
                    value={formik.values.state} />
                <Button type='primary' onClick={formik.handleSubmit} className='w-100'>Save</Button>
            </Form>
        )
    }



    const setAddresssValues = (res) => {
        updateformik.setFieldValue("addressId", res._id)
        updateformik.setFieldValue("name", res.name)
        updateformik.setFieldValue("contactNo", res.contactno)
        updateformik.setFieldValue("flat", res.flat)
        updateformik.setFieldValue("area", res.area)
        updateformik.setFieldValue("landmark", res.landmark)
        updateformik.setFieldValue("town", res.town)
        updateformik.setFieldValue("pincode", res.pincode)
        updateformik.setFieldValue("state", res.state)
    }

    const editAddressForm = () => {
        return (
            <Form action='' onSubmit={updateformik.handleSubmit} className='checkOutAddressForm'>
                <Input
                    onChange={updateformik.handleChange("name")}
                    onBlur={updateformik.handleBlur("name")}
                    placeholder='Name'
                    value={updateformik.values.name} />
                <Input
                    onChange={updateformik.handleChange("contactNo")}
                    onBlur={updateformik.handleBlur("contactNo")}
                    placeholder='Contact No'
                    value={updateformik.values.contactNo} />
                <Input
                    onChange={updateformik.handleChange("flat")}
                    onBlur={updateformik.handleBlur("flat")}
                    placeholder='Flat/Apartment/House'
                    value={updateformik.values.flat} />
                <Input
                    onChange={updateformik.handleChange("area")}
                    onBlur={updateformik.handleBlur("area")}
                    placeholder='Area/Street'
                    value={updateformik.values.area} />
                <Input
                    onChange={updateformik.handleChange("landmark")}
                    onBlur={updateformik.handleBlur("landmark")}
                    placeholder='Landmark'
                    value={updateformik.values.landmark} />
                <Input
                    onChange={updateformik.handleChange("town")}
                    onBlur={updateformik.handleBlur("town")}
                    placeholder='Town'
                    value={updateformik.values.town} />
                <Input
                    onChange={updateformik.handleChange("pincode")}
                    onBlur={updateformik.handleBlur("pincode")}
                    placeholder='PIN Code'
                    value={updateformik.values.pincode} />
                <Input
                    onChange={updateformik.handleChange("state")}
                    onBlur={updateformik.handleBlur("state")}
                    placeholder='State'
                    value={updateformik.values.state} />
                <div></div>
                <div></div>
                <div className='d-flex gap-2'>
                    <Button type='primary' onClick={() => setShowEditAddressForm(false)} className='bg-secondary w-100'>Cancel</Button>
                    <Button type='primary' onClick={updateformik.handleSubmit} className='w-100'>Update</Button>
                </div>
            </Form>
        )
    }

    let sum = 0
    cart.forEach(element => {
        sum = (sum + parseFloat(element.price)) * element.qty
    });
    
    const removeItemFromCart = (e) => {
        const data = {
            _id: e,
            custId: JSON.parse(Cookies.get("udtl")).uid
        }
        dispatch(delteCartItem(data))
    }
    const saveCart = (a, b, c, d, e, f) => {
        const data = {
            qty: a,
            size: d,
            color: e,
            custId: b,
            productID: c,
            price: f
        }
        dispatch(createCartSlice(data))
    }

    return (
        <div className='container pt-5 cheoutContainer mb-5'>

            <div className=' d-flex flex-column gap-3'>
                <div className='bg-white  p-relative'>
                    <div className={deliveryAddressToggle && !orderSummaryToggle && !paymentToggle
                        ? "checkout-container-sections" : !deliveryAddressToggle && orderSummaryToggle && !paymentToggle
                            ? "hidden" : !deliveryAddressToggle && !orderSummaryToggle && paymentToggle
                                ? "hidden" : null
                    }
                    >
                        1. Delivery Address
                    </div>
                    <div
                        className={deliveryAddressToggle ? "hidden" : "checkout-container-sections-done"} >
                        1. Delivery Address <MdOutlineDone style={{ color: "green", fontSize: "30px" }} />
                    </div>

                    <div className={deliveryAddressToggle ? "show" : "hidden"}>
                        {adresss.map((res, i) => (
                            <div>
                                <div
                                    style={{
                                        position: "relative",
                                        // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                        borderBottom: "1px solid #e6e6e6",
                                        padding: '10px 5px 3px 14px',
                                        // borderRadius: "10px",
                                        cursor: 'pointer',
                                        // display: addressId === res._id ? "none" : "flex",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: 'center',
                                        paddingBottom: "15px"
                                    }}

                                >

                                    <div>
                                        <div>
                                            <div>
                                                <strong>{res.name}</strong>, <div>{res.flat}, {res.landmark}, {res.state}-{res.pincode}</div></div>
                                            {/* <div className={addressId === res._id ? "address-selection-banner" : "hidden"}>Selected</div> */}
                                        </div>
                                        <Button
                                            type="primary"
                                            style={{
                                                background: "orange",
                                                padding: "5px",
                                                color: 'white'
                                            }}
                                            onClick={() => {
                                                setAddressId(res._id)
                                                openOrderSummary()
                                                setShowAddAddressForm(false)
                                            }}
                                        >Deliver here</Button>
                                    </div>
                                    <div
                                        style={{
                                            fontWeight: "bold",
                                            color: "#2a96f9",
                                            textAlign: "end",
                                            paddingRight: "20px"
                                        }}
                                        onClick={() => {
                                            setAddressId(res._id)
                                            setShowAddAddressForm(false)
                                            setShowEditAddressForm(true)
                                            setAddresssValues(res)
                                        }}>Edit</div>
                                </div>
                                {addressId === res._id && showEditAddressForm ? <div><strong>Edit Address</strong>{editAddressForm()}</div> : null}
                            </div>
                        ))}
                        <div className={showAddAddressForm ? "hidden" : "show text-center p-2"}  >
                            <Button onClick={() => setShowAddAddressForm(true)}>Add Address</Button>
                        </div>


                    </div>


                    <div
                        className={!deliveryAddressToggle ? "show" : "hidden"}
                    >
                        {adresss.map((res, i) =>
                            addressId === res._id ? (
                                <div
                                    key={i}
                                    style={{
                                        position: "relative",
                                        padding: ' 10px 20px',
                                        fontSize: "14px",
                                        cursor: 'pointer',
                                    }}
                                >
                                    <div>
                                        <div>
                                            <strong>{res.name}</strong>,
                                            <div>{res.flat}, {res.landmark}, {res.state}-{res.pincode}</div>
                                        </div>
                                        {/* <div className={addressId === res._id ? "address-selection-banner" : "hidden"}>Selected</div> */}
                                    </div>
                                </div>
                            ) : null

                        )}
                    </div>
                    <Button
                        className={!deliveryAddressToggle ? "show changeButton" : "hidden"}
                        onClick={() => openDeliveryAddress()}>Change</Button>

                </div>


                {showAddAddressForm ? <div className='bg-white mt-4 mb-4'> {loadAddNewAddressForm()}</div> : null}

                <div className='bg-white  p-relative'>
                    <div
                        className={deliveryAddressToggle && !orderSummaryToggle && !paymentToggle
                            ? "checkout-container-sections-done" : !deliveryAddressToggle && orderSummaryToggle && !paymentToggle
                                ? "checkout-container-sections" : !deliveryAddressToggle && !orderSummaryToggle && paymentToggle
                                    ? "hidden" : null
                        }
                    >
                        2. Order Summary
                    </div>
                    <div
                        className={deliveryAddressToggle && !orderSummaryToggle && !paymentToggle
                            ? "hidden" : !deliveryAddressToggle && orderSummaryToggle && !paymentToggle
                                ? "hidden" : !deliveryAddressToggle && !orderSummaryToggle && paymentToggle
                                    ? "checkout-container-sections-done" : null
                        }
                    >
                        2. Order Summary
                        {deliveryAddressToggle && !orderSummaryToggle && !paymentToggle
                            ? "hidden" : !deliveryAddressToggle && orderSummaryToggle && !paymentToggle
                                ? "hidden" : !deliveryAddressToggle && !orderSummaryToggle && paymentToggle
                                    ? <MdOutlineDone style={{ color: "green", fontSize: "30px" }} /> : null
                        }
                    </div>
                    <div
                        className={orderSummaryToggle ? "show" : "hidden"}>
                        <div className='mt-4 d-flex flex-column gap-2'>
                            {
                                cart.map((res, i) => (
                                    <div className='d-flex gap-2'>
                                        <img src={res?.products[0]?.finalVariantList[0]?.imageArray[0]?.url} alt={res?.products[0]?.finalVariantList[0]?.imageArray[0]?.url} style={{ height: "100px" }} />
                                        <div>
                                            <strong>{res?.products[0]?.title}</strong>
                                            <div style={{ fontSize: "12px" }} className='text-secondary'>Size : {res?.size}</div>
                                            <div style={{ fontSize: "12px" }} className='text-secondary'>Color : {res?.color}</div>
                                            <div style={{ fontSize: "12px", fontWeight: "800" }} className='text-secondary'>Price : ₹ {parseFloat(res?.price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                            <div className='d-flex gap-2'>
                                                <select className='text-center' onChange={(e) => saveCart(e.target.value, res.custId, res.productID, res.size, res.color, res.price)} style={{ padding: "5px", border: '1px solid #e0e0e0', borderRadius: '7px' }}>
                                                    <option>{res.qty}</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                </select>
                                                <div className='text-danger mt-2' onClick={() => removeItemFromCart(res._id)} style={{ cursor: 'pointer' }}>
                                                    Remove
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <hr className='m-0' />
                            <div className="p-3 d-flex">
                                {cart.length > 0 ? (
                                    <div>Order information will send to {JSON.parse(Cookies.get('udtl')).email}</div>
                                ) : null}
                                {cart.length > 0 ? (
                                    <Button type='primary' onClick={() => openPaymentSection()}>Continue</Button>
                                ) : (<b>Your cart is Empty! Please add items to prceed checkout</b>)}
                            </div>
                        </div>
                    </div>
                    <div
                        className={deliveryAddressToggle && !orderSummaryToggle && !paymentToggle
                            ? "hidden" : !deliveryAddressToggle && orderSummaryToggle && !paymentToggle
                                ? "hidden" : !deliveryAddressToggle && !orderSummaryToggle && paymentToggle
                                    ? "show p-3" : null
                        }
                    >

                        <strong>{cart.length} Item(s)</strong>
                    </div>
                    <Button
                        className={deliveryAddressToggle && !orderSummaryToggle && !paymentToggle
                            ? "hidden" : !deliveryAddressToggle && orderSummaryToggle && !paymentToggle
                                ? "hidden" : !deliveryAddressToggle && !orderSummaryToggle && paymentToggle
                                    ? "show changeButton" : null
                        }
                        onClick={() => openOrderSummary()}>Modify</Button>
                </div>


                <div className='bg-white'>
                    <div
                        className={deliveryAddressToggle && !orderSummaryToggle && !paymentToggle
                            ? "checkout-container-sections-done" : !deliveryAddressToggle && orderSummaryToggle && !paymentToggle
                                ? "checkout-container-sections-done" : !deliveryAddressToggle && !orderSummaryToggle && paymentToggle
                                    ? "checkout-container-sections" : null
                        }
                    >
                        3. Payment Options
                    </div>
                    <div
                        className={deliveryAddressToggle && !orderSummaryToggle && !paymentToggle
                            ? "hidden" : !deliveryAddressToggle && orderSummaryToggle && !paymentToggle
                                ? "hidden" : !deliveryAddressToggle && !orderSummaryToggle && paymentToggle
                                    ? "hidden" : null
                        }
                    >
                        3. Payment Options
                    </div>
                    <div className={paymentToggle ? "show p-2" : "hidden"}
                    >
                        <div>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="gapy"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value="gpay" control={<Radio select />} label="Gpay" />
                                    <FormControlLabel value="wallet" control={<Radio />} label="Wallet" />
                                    <FormControlLabel value="cards" control={<Radio />} label="Cards" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <LoadingButton loading={isLoading} variant='contained' onClick={() => makeOrder()}>Place Order</LoadingButton>

                    </div>
                </div>
            </div>


            <div className='bg-white  p-4'>
                <div className='text-secondary' style={{ fontWeight: "600", fontSize: "18px" }}>Price Details</div>
                <Divider className='mt-2 mb-2' />
                <div className='d-flex justify-content-between'>
                    <div>Price ({cart.length})</div>
                    <div className='text-end'>₹ {sum.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                </div>
                <div className='d-flex justify-content-between'   >

                    <div>Shipping & Delivery Charges</div>
                    <div className='text-end text-success'>Free</div>
                </div>
                <div></div>
                <Divider />
                <div className='d-flex justify-content-between'>
                    <div style={{ fontWeight: "600", fontSize: "25px" }}>Amount Payable</div>
                    <div style={{ fontWeight: "600", fontSize: "25px" }} className='text-end'>₹ {(sum).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                </div>
            </div>



            {/* <div className='w-100'>
                <div>Select Address</div>
                {adresss.map((res, i) => (
                    <div
                        className='m-2'
                        style={{
                            position: "relative",
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            padding: '20px 10px 20px 50px',
                            borderRadius: "10px",
                            cursor: 'pointer',
                        }}
                        onClick={() => setAddressId(res._id)}
                    >
                        <div>{res.name},</div>
                        <div>{res.flat},</div>
                        <div>{res.landmark},</div>
                        <div>{res.state}-{res.pincode}</div>
                        <div className={addressId === res._id ? "address-selection-banner" : "hidden"}>Active</div>
                    </div>
                ))}
                <Divider />

            </div> */}

            {/* <div className='payment-success-product-container'>

                <table className='payment-success-product-table '>
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th>Price</th>
                            <th>Qty</th>
                            <th className='text-end'>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cart.map((res, i) => (
                            <tr>
                                <td>{res.products[0].title}</td>
                                <td>₹ {res.price}</td>
                                <td>{res.qty} <div style={{ display: 'none' }}>{subTotal = subTotal + (res.qty * res.price)}</div></td>
                                <td className='text-end'>₹ {parseFloat(res.qty * res.price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className='d-flex justify-content-end mt-4'>
                    <div className='text-end'>Sub Total </div>
                    <div className='text-end ms-4'>₹ {parseFloat(subTotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                </div>
                <div className='d-flex justify-content-end mt-0'>
                    <div className='text-end'>Shipping </div>
                    <div className='text-end ms-4'>₹ 30.00</div>
                </div>
                <div className='d-flex justify-content-end mt-1'>
                    <div className='text-end'><strong>Grand Total</strong></div>
                    <div className='text-end ms-4'><strong>₹ {(subTotal + 30).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></div>
                </div>
                <div className='mt-4 text-end'>
                    <Link to='/'>
                        <LoadingButton loading={isLoading} variant='contained' onClick={() => makeOrder()}>Place Order</LoadingButton>
                    </Link>
                </div>
                <Divider />
                <div className='mt-4'>
                    <div><strong>Order Date : {moment(Date()).format("MMMM  DD , YYYY")}</strong></div>
                </div>
                <div className='mt-2 mb-2'><u><i>Terms & Conditions</i></u> <br /> We email you an order confirmation with details and  tracking info</div>
            </div> */}
        </div >
    )
}

export default Checkout