import React from 'react'
import './footer.css'
import { AiOutlineInstagram } from 'react-icons/ai'
import { BsYoutube } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div>
            <footer className='ddddxx p-4'>
                <div className='text-center'>
                    <strong className='mb-3'>HOW CAN WE HELP?</strong>
                    <div className='text-center  gap-4'>
                        <ul className='footer-menu-items'>
                            <li><small><Link to='/support'>Customer Service</Link></small></li>
                            <li><small><Link to='/shipping&delivery'>Shipping & Delivery</Link></small></li>
                            <li><small><Link to='/returns&exchange'>Returns & Exchange</Link></small></li>
                            <li><small><Link to='/faqs'>FAQs</Link></small></li>
                        </ul >
                    </div >
                </div >
                <div className='text-center'>
                    <strong className='mb-3'>USEFUL INFO</strong>
                    <div className='text-center'>
                        <ul className='footer-menu-items'>
                            <li><small><Link to='/terms&conditions'>Terms & Conditions</Link></small></li>
                            <li><small><Link to='/privacy-policy'>Privacy policy & Cookies</Link></small></li>
                        </ul >
                    </div >
                </div >
                <div className='text-center'>
                    <strong className='mb-3'>INSIDE MILY</strong>
                    <div>
                        <ul className='footer-menu-items'>
                            <li><small>About Us</small></li>
                            <li><small>Our Story</small></li>
                            <li><small>Contact Us</small></li>
                        </ul>
                    </div>
                </div>
            </footer >
            <div className='d-flex gap-5 p-3 justify-content-center mt-2' style={{ fontSize: "30px" }}>
                <Link to='https://www.facebook.com/Milyonline.in' className='text-decoration-none'><FaFacebookF style={{ fontSize: "20px" }} /></Link>
                <Link to='https://www.instagram.com/Milyonline.in' className='text-decoration-none'> <AiOutlineInstagram style={{ fontSize: "20px" }} /></Link>
                {/* <BsYoutube /> */}
            </div>
        </div >
    )
}

export default Footer