import React, { useEffect, useRef, useState } from 'react';
import Carousel1 from '../../components/home/Carousel1';
import ShopNow from '../../components/home/ShopNow';
import ExploreCollection from '../../components/home/ExploreCollection';
import Banner3 from '../../components/home/Banner3';
import ShippingContainer from '../../components/home/ShippingContainer';
import MustHave from '../../components/home/MustHave';
import OfferContainer from '../../components/home/OfferContainer';
import OnamCollection from '../../components/home/OnamCollection';
import Mily from '../../components/home/Mily';
import NewArrivals from '../../components/home/NewArrivals';
import Trending from '../../components/home/Trending';
import Featured from '../../components/home/Featured';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Categories from '../../components/home/Categories';
import Shopping from '../../components/home/Shopping';
import AdSectionOne from '../../components/home/AdSectionOne';
import AdSectionTwo from '../../components/home/AdSectionTwo';
import AdSectionThree from '../../components/home/AdSectionThree';

function Home() {
    const [animationVisible, setAnimationVisible] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const animationRefs = useRef([]);

    useEffect(() => {
        const handleScroll = () => {
            // Get the current scroll position
            const currentScrollPos = window.pageYOffset;
            // Check if scrolling down or up
            if (currentScrollPos > prevScrollPos) {
                // Scrolling down - show the animations
                setAnimationVisible(true);
            } else {
                // Scrolling up - hide the animations
                setAnimationVisible(false);
            }

            // Save the current scroll position
            setPrevScrollPos(currentScrollPos);
        };

        // Add the event listener to handle scroll events
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    return (
        <div className='animation'>
            <div style={{ marginTop: '20px ' }}>&nbsp;</div>
            <Carousel1 />
            <div className='divider-xx'></div>
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeInUp' : 'animate__fadeInDown'} >
                <Categories />
            </AnimationOnScroll>
            <div className='divider-xx'></div>
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeInUp' : 'animate__fadeInDown'} >
                <Shopping />
            </AnimationOnScroll>
            <div className='divider-xx'></div>
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeInUp' : 'animate__fadeInDown'} >
                <AdSectionTwo />
            </AnimationOnScroll>
            <div className='divider-xx'></div>
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeInUp' : 'animate__fadeInDown'} >
                <Banner3 />
            </AnimationOnScroll>
            {/* <div className='divider-xx'></div>
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeInUp' : 'animate__fadeInDown'} >
                <NewArrivals />
            </AnimationOnScroll>
            <div className='divider-xx'></div>
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeInUp' : 'animate__fadeInDown'} >
                <OnamCollection />
            </AnimationOnScroll>
            <div className='divider-xx'></div>
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeInUp' : 'animate__fadeInDown'} >
                <Featured />
            </AnimationOnScroll>*/}
            <div className='divider-xx'></div>
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeInUp' : 'animate__fadeInDown'} >
                <AdSectionOne />
            </AnimationOnScroll>
            {/* <div className='divider-xx'></div> */}
            {/* <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeInUp' : 'animate__fadeInDown'} >
                <Trending />
            </AnimationOnScroll > */}
            {/* <div className='divider-xx'></div>
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeInUp' : 'animate__fadeInDown'} >
                <OfferContainer />
            </AnimationOnScroll>
            <div className='divider-xx'></div>
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeInUp' : 'animate__fadeInDown'} >
                <ShopNow />
            </AnimationOnScroll>
            <div className='divider-xx'></div>
            {/* <Handpicked />  
            <div className='divider-xx'></div>
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeInUp' : 'animate__fadeInDown'} >
                <MustHave />
            </AnimationOnScroll> */}
            <div className='divider-xx'></div>
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeInUp' : 'animate__fadeInDown'} >
                <ExploreCollection />
            </AnimationOnScroll>
            <div className='divider-xx'></div>
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeInUp' : 'animate__fadeInDown'} >
                <ShippingContainer />
            </AnimationOnScroll>
            <div className='divider-xx'></div>
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeIn' : 'animate__fadeOut'} >
                <Mily />
            </AnimationOnScroll>
            {/* <div className='divider-xx'></div> */}
            <AnimationOnScroll animateIn={animationVisible ? 'animate__fadeIn' : 'animate__fadeOut'} >
                <AdSectionThree />
            </AnimationOnScroll>
            {/* <QuiltedImageList /> */}
            {/* <MainCategories /> */}
            {/* <BannerTwo /> */}
        </div >
    );
}

export default Home;
