import React from 'react'
import photo from '../../images/mily.png'
import { Col, Row } from 'react-bootstrap'
import logo from '../../images/logo.png'

function Mily() {
    return (
        <div className='container bg-white p-3'>
            <Row className='assassa'>
                {/* <Col xs={12} sm={6} md={4} lg={2}>
                    <img src={logo} alt='' className='w-100' />
                </Col> */}
                <Col xs={12} sm={6} md={4} lg={2} >
                    <div className='text-end'>
                        <img src={photo} alt='' className='mily-img-img w-100' />
                    </div>
                    <div className='d-flex align-items-center justify-content-end'>
                        <div>
                            <img src={logo} alt='' style={{ height: '50px', marginTop: '20px', marginBottom: '20px' }} />
                        </div>
                        <div className='text-end mt-1'>
                            <strong style={{ fontSize: "1.2rem" }}>Misha Denny</strong> <br />
                            <div className='text-secondary'> Founder, Mily </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={6} md={4} lg={10}>
                    <div style={{ fontWeight: 600, fontSize: '1.1rem' }} className='mb-3'>Your identity and self-expression are in your hands. You determine who you are and what you wish to convey through your clothing and lifestyle choices.</div>
                    <p>At Mily Boutique, we believe that fashion is a powerful form of self-expression. Our online store is a curated collection of exquisite and trendy clothing, handpicked to cater to your unique taste and personality. From chic and sophisticated to casual and comfortable, we offer an array of clothing choices that will make you stand out in any crowd.
                        Shop with confidence, as we prioritize customer satisfaction and aim to provide a seamless shopping experience. We offer secure payment options, hassle-free returns, and timely delivery to your doorstep.
                    </p><p>Join us on this fashionable journey, and let Mily Boutique be your go-to destination for all your style needs. Elevate your wardrobe and embrace the art of self-expression with us today!</p>
                    <p>Remember, at Mily Boutique, it's not just about the clothes; it's about creating a lasting impression through your unique sense of style. Happy shopping!</p>
                </Col>
            </Row>
        </div>
    )
}

export default Mily