import { createAsyncThunk, createSlice } from "@reduxjs/toolkit" 
import featuredService from "./FeaturedService"

const initialState = {
    featuredItem: [],
    isLoading: false,
    isError: false,
    isSuccess: false
}

export const getFeatured = createAsyncThunk(
    "get/getFeatured",
    async (thunkAPI) => {
        try {
            return await featuredService.getFeatured()
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const featuredSlice = createSlice({
    name: "featured",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFeatured.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getFeatured.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.featuredItem = action.payload
            })
            .addCase(getFeatured.rejected, (state) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })
    }
})
export default featuredSlice.reducer