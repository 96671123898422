import React from 'react'

function ReturnandExchange() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
  return (
    <div className="container p-5 animation">
      <h2>Time Limit</h2>
      <p>Customers must initiate the exchange process within <b>2 days</b> from the date of delivery of the product. After this period, the store may not accept the item for exchange.</p>
      <p>The product will be picked up from your doorstep, and the courier arrangements required for this will be our sole responsibility.</p>
      <p>Once we get the product in our warehouse, it shall be put under quality testing.</p>

      <h2>Conditions for Exchange</h2>
      <p>Mily may decline to accept a return in certain situations. The following conditions must be met to validate a return request:</p>
      <ul>
        <li>The product must be unused.</li>
        <li>It must not have any stain, sweat, or body odor on it.</li>
        <li>It must not be torn or damaged.</li>
        <li>The price tags and/or paper tags should be unaltered.</li>
        <li>The packaging and folding should be intact.</li>
        <li>The product must not be washed or dry cleaned.</li>
      </ul>
      <p>Products purchased on discount/offers/sale are <strong>INELIGIBLE</strong> for Exchange.</p>
      <p>Products that are made on the “Pre-Order” basis are not eligible for any exchanges unless the product is damaged.</p>
      <p>Exchange for Designer Sarees worth more than ₹10,000.00INR will be accepted only in the case of a manufacturing defect or dispatch of incorrect item.</p>

      <h3>Alternative Options</h3>
      <p>If the exchange item is not available, the customer may be offered the following alternatives:</p>
      <strong>Purchase Higher Amount Product by Paying Extra:</strong> Alternatively, the customer may have the option to choose a higher-priced product and pay the difference in price between the returned item and the new item.
      {/* <ol>
        <li>
          <strong>Equal Amount of Coupon:</strong> In this case, the customer may receive a coupon or store credit for the same value as the returned item. The coupon can be used for future purchases at the store.
        </li>
        <li>
          <strong>Purchase Higher Amount Product by Paying Extra:</strong> Alternatively, the customer may have the option to choose a higher-priced product and pay the difference in price between the returned item and the new item.
        </li>
      </ol> */}

    </div>
  )
}

export default ReturnandExchange