import React from 'react'
import shop1 from '../../images/shopping-image-1 .webp'
import shop2 from '../../images/Kids Style.jpeg'
import shop3 from '../../images/il_1080xN.4720054278_ja0u.jpg'
import './shopping.css'
import { Link } from 'react-router-dom'

const images = [
    {
        images: shop1,
        text: "Men's Style",
        subText: "Best Styles for all Men"
    },
    {
        images: shop2,
        text: "Girls's Style",
        subText: "Best Styles for all Girls"
    },
    {
        images: shop3,
        text: "Women's Style",
        subText: "Best Styles for all Women"
    }
]

function Shopping() {

    const containerStyle = {
        position: "relative",
        width: '350px'
    }

    const subContentOneStyle = {
        position: "absolute",
        bottom: "10%",
        background: "#ffffffcc",
        padding: '20px',
        borderRadius: '0 10px 10px 0',
        left: '0',
        width: '250px'
    }

    return (
        <div className='container bg-white p-3'>
            <div className='home-shopping-container'>
                {images.map((res, i) => (
                    <div key={i} style={containerStyle}>
                        <img src={res.images} className='w-100' />
                        <Link to={'/Products'} style={subContentOneStyle}>
                            <div style={{ background: "#000000", color: '#ffffffcc', fontWeight: 'bold', padding: '10x', borderRadius: '10px', padding: '0 15px' }}>{res.text}</div>
                            <div style={{ fontSize: '20px' }} className='mt-2'>{res.subText}</div>
                            <div style={{
                                fontSize: '14px',
                                fontWeight: 'bolder',
                                border: '1px solid #00acb2',
                                padding: '5px',
                                borderRadius: '5px',
                                textAlign: 'center'
                            }}>Shop Now</div>
                        </Link>
                    </div>
                ))}
            </div>
        </div >
    )
}

export default Shopping