import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    showLoginForm: false
};
export const loginSlice = createSlice({
    name: "login-state",
    initialState,
    reducers: {
        loginState: (state, action) => {
            state.showLoginForm = action.payload;
        }
    }
});
export const { loginState } = loginSlice.actions;
export default loginSlice.reducer;