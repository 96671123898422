import React from 'react'

function faq() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
  return (
    <div className='container p-5 animation'>
      <h1>Frequently Asked Questions</h1>

      <div className="question" onclick="toggleAnswer('q1')">Q1: What payment methods do you accept?</div>
      <div className="answer" id="q1">A1: We accept all major credit cards and PayPal.</div>

      <div className="question" onclick="toggleAnswer('q2')">Q2: How do I track my order?</div>
      <div className="answer" id="q2">A2: Once your order is shipped, you will receive a tracking number via email. You can use this number to track your package.</div>

      <div className="question" onclick="toggleAnswer('q3')">Q3: What is your return policy?</div>
      <div className="answer" id="q3">A3: We offer a 30-day return policy. If you are not satisfied with your purchase, you can return the item for a full refund.</div>

    </div>
  )
}

export default faq