import React from 'react'
import banner from '../../images/blueAd.png'

function AdSectionTwo() {
    return (
        <div className='w-100 '>
            <img src={banner} style={{ width: '100%' }} />
        </div>
    )
}

export default AdSectionTwo