import axios from "axios";

const storedToken = sessionStorage.getItem('usr');
const token = storedToken ? JSON.parse(storedToken) : null;

export const INSTANCE = axios.create({
    headers: {
        Authorization: token ? `Bearer ${token.token}` : '',
        Accept: "application/json"
    }
});

export const IMAGE_INSTANCE = axios.create({
    headers: {
        Authorization: token ? `Bearer ${token.token}` : '',
        Accept: "multipart/form-data"
    }
});
