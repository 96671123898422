import React from 'react'
import './Categories.css'
import girls from '../../images/Girls Category.png'
import women from '../../images/Women Category.png'
import boys from '../../images/Boys category.png'
import men from '../../images/Men Category.png'
import dress from '../../images/Dresses category.png'
import accessories from '../../images/Accessories Category.png'
import { Link } from 'react-router-dom'

const images = [
    {
        image: girls,
        title: 'Girls',
        link: 'girls',
    },
    {
        image: women,
        title: 'Women',
        link: 'women',
    },
    {
        image: boys,
        title: 'Boys',
        link: 'boys',
    },
    {
        image: men,
        title: 'Men',
        link: 'men',
    },
    {
        image: dress,
        title: 'Dress',
        link: 'dress',
    },
    {
        image: accessories,
        title: 'Accessories',
        link: 'accessories',
    }

]
function Categories() {
    return (
        <div className='home-category-container bg-white' >
            <div className='container pb-4 pt-4'>
                <h2 className='text-center mt-4'>Shop By Categories</h2>
                <div className='home-category-container-inner mt-4'>
                    {images.map((res, i) => (
                        <Link to={"/categories/" + res.title} key={i} className='d-flex flex-column align-items-center gap-2'>
                            <img src={res.image} className='category-image' />
                            <strong style={{ color: '#000' }}>{res.title}</strong>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Categories