import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    cartOpen: false
}

export const createCartOpenSlice = createSlice({
    name: "cartOpen",
    initialState,
    reducers: {
        cartState: (state, action) => {
            state.cartOpen = action.payload
        }
    }
})

export const { cartState } = createCartOpenSlice.actions
export default createCartOpenSlice.reducer