import React from 'react'
import './ExploreCollection.css'
import subBanner from '../../images/subbanner.png'
import { Link } from 'react-router-dom'

const exploreContent = [
    {
        title: "Energetic Casualwear",
        text: "Introducing our Energetic Casualwear collection, where style meets comfort in perfect harmony. Embrace the vibrant spirit of youth and the dynamism of modern fashion with our carefully curated range of casual attire. Designed to keep you looking effortlessly chic, our collection boasts a fusion of bold colors, trendy prints, and contemporary silhouettes that exude a playful energy. Whether you're out for a casual outing with friends or seeking a relaxed yet fashionable ensemble for your everyday adventures, our Energetic Casualwear ensures you stay on-trend while feeling at ease.",
        image: subBanner
    }
]

function ExploreCollection() {
    return (
        <div className='mb-5'>

            {exploreContent.map((res, i) => (
                <div className='container p-5 explore-collection-container' style={{ background: '#fff' }} key={i}>
                    <div>
                        <strong style={{ fontSize: "28px", color: "#0064d8" }}>{res.title}</strong>
                        <p></p>
                        <p style={{ textAlign: "justify" }}>{res.text}</p>
                        <div className='text-center'>
                            <Link to={"/products"} className='btn btn-outline-secondary'>Shop Now!</Link>
                        </div>
                    </div>
                    <div>
                        <img src={res.image} alt='' style={{ height: "300px", objectFit: "contain" }} />
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ExploreCollection