import React from 'react'
import { Container } from 'react-bootstrap'
import { FaShippingFast } from 'react-icons/fa'
import { BiSupport } from 'react-icons/bi'
import { GiReturnArrow } from 'react-icons/gi'


function ShippingContainer() {
    return (
        <Container>
            <div className='shippingContainer'>
                <div className='shpngctnrs'>
                    <div><FaShippingFast style={{ color: "#007af8", fontSize: "50px" }} /></div>
                    <div className='shpitmcntr'>
                        <strong>FREE SHIPPING</strong>
                        <small>Free shipping all over India on orders above ₹999</small>
                    </div>
                </div>
                <div className='shpngctnrs'>
                    <div><BiSupport style={{ color: "#007af8", fontSize: "50px" }} /></div>
                    <div className='shpitmcntr'>
                        <strong>SUPPORT 24/7</strong>
                        <small>Contact us 24 hours a day, 7 days a week</small>
                    </div>
                </div>

                <div className='shpngctnrs'>
                    <div><GiReturnArrow style={{ color: "#007af8", fontSize: "50px" }} /></div>
                    <div className='shpitmcntr'>
                        <strong>15 DAYS EXCHANGE</strong>
                        <small>Simply exchange it within 15 days.</small>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default ShippingContainer