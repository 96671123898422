import { ORDERS } from "../../const/ApiConst"
import { INSTANCE } from "../../const/Header"

export const getAllOrders = async (custID) => {
    const response = await INSTANCE.post(`${ORDERS}/get-orders`, custID)
    return response.data
}

export const getSingleOrder = async (orderID) => {
    const response = await INSTANCE.post(`${ORDERS}/single-order`, orderID)
    return response.data
}

const orderService = {
    getAllOrders,
    getSingleOrder
}

export default orderService