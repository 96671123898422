import { FormControlLabel, FormLabel, Radio, RadioGroup, Slider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '../../store/category/CategorySlice';
import { getFilteredService } from '../../store/filter/FilterSlice';
import { getSize } from '../../store/size/SizeSlice';
import { Collapse } from 'antd';
import { getFilteredValueService } from '../../store/product/ProductSlice';
import { AiOutlineClose } from 'react-icons/ai'


function valuetext(value) {
    return `${value}°C`;
}

const minDistance = 10;

function Filter() {

    const [value1, setValue1] = React.useState([0, 5]);
    const dispatch = useDispatch()


    const handleChange1 = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }
        if (activeThumb === 0) {
            setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
        } else {
            setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
        }
    };

    const { categories } = useSelector((state) => state?.categories)


    const items = [];


    for (let i = 0; i < categories.length; i++) {
        if (categories[i].parentCategory === 'Parent') {
            const data = {
                label: categories[i].categoryName,
                slug: categories[i].slug,
                key: i.toString(),
                children: []
            };
            items.push(data);
        } else {
            for (let j = 0; j < items.length; j++) {
                if (items[j].label === categories[i].parentCategory) {
                    const subData = {
                        label: categories[i].categoryName,
                        key: i.toString(),
                    };
                    items[j].children.push(subData);
                }
            }

        }
    }


    useEffect(() => {
        dispatch(getCategories())
        dispatch(getSize())
    }, [])


    const [filteredSizeValue, setFilteredSizeValue] = useState("")
    const [filteredColorValue, setFilteredColorValue] = useState("")

    const sizeFilterAction = (e) => {

        setFilteredSizeValue(e)

        const data = {
            sizeFilter: e,
            colorFilter: filteredColorValue
        }

        dispatch(getFilteredValueService(data))

    }

    const colorFilterAction = (e) => {

        setFilteredColorValue(e)
        const data = {
            sizeFilter: filteredSizeValue,
            colorFilter: e
        }
        dispatch(getFilteredValueService(data))


    }

    const [categoryFilterValue, setCategoryFilterValue] = useState("")
    const [sizeFilterValue, setSizeFilterValue] = useState("")



    const pdtSizes = useSelector((state) => state?.product?.products)

    const allSizes = [];

    console.log(pdtSizes);

    pdtSizes.forEach((rea) => {
        rea.finalVariantList.forEach((reb) => {
            reb.sizeArray.forEach((rec) => {
                if (!allSizes.includes(rec.size)) {
                    allSizes.push(rec.size);
                }
                // allSizes.push(rec.size);
            });
        });
    });

    const allColors = [];
    pdtSizes.forEach((rea) => {
        rea.finalVariantList.forEach((reb) => {
            if (!allColors.includes(reb.colorTitle)) {
                allColors.push(reb.colorTitle);
            }
        });
    });



    var sizeData = [{
        key: 1,
        label: <strong>Size</strong>,
        children: <div ><RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
        >
            <FormControlLabel
                value=""
                control={<Radio />}
                label="All"
                onChange={(e) => sizeFilterAction("")} />
            {allSizes.map((res, i) => (
                <FormControlLabel
                    value={res}
                    control={<Radio />}
                    label={res}
                    onChange={(e) => {
                        sizeFilterAction(e.target.value)
                    }} />
            ))}

        </RadioGroup></div>
    }]


    var colorData = [{
        key: 1,
        label: <strong>Colors</strong>,
        children: <div ><RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
        >
            <FormControlLabel
                value=""
                control={<Radio size='small' />}
                label="All"
                onChange={() => colorFilterAction("")} />
            {allColors.map((res, i) => (
                <FormControlLabel
                    value={res}
                    control={<Radio size="small" />}
                    label={res}
                    onChange={(e) => colorFilterAction(e.target.value)} />
            ))}

        </RadioGroup></div>
    }]

    const onChange = (key) => {
        // console.log(key);
    };


    useEffect(() => {
        const updatedItems = categories?.map((item, i) =>
            item.parentCategory === 'Parent'
                ? {
                    key: i,
                    label: <b>{item.categoryName}</b>,
                    children: categories
                        .filter((resx) => resx.parentCategory === item.categoryName)
                        .map((resx) => <div className='filter-sub-menu-items'>{resx.categoryName}</div>),
                }
                : null
        );
        const filteredItems = updatedItems.filter((item) => item !== null);

    }, [categories]);

    const filteredItemsStyle = {
        background: "white",
        padding: "5px 10px",
        margin: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }

    return (
        <div className='d-flex flex-column gap-4' >
            <div>
                {filteredColorValue !== "" || filteredSizeValue !== "" ? (
                    <div>
                        <div>Active Filters</div>
                        {filteredColorValue !== "" ? <div style={filteredItemsStyle}><div>{filteredColorValue}</div><AiOutlineClose style={{ cursor: "pointer" }} onClick={(e) => colorFilterAction("")} /></div> : null}
                        {filteredSizeValue !== "" ? <div style={filteredItemsStyle}><div>{filteredSizeValue}</div><AiOutlineClose style={{ cursor: "pointer" }} onClick={(e) => sizeFilterAction("")} /></div> : null}

                    </div>
                ) : null
                }
            </div >
            {/* <div >
                <div>Price</div>
                <div>
                    <Slider
                        getAriaLabel={() => 'Minimum distance'}
                        value={value1}
                        onChange={handleChange1}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        disableSwap
                    />
                </div>
                <div className='d-flex justify-content-between gap-4'>
                    <input type='number' style={{ width: "60px" }} placeholder='min' />
                    <input type='number' style={{ width: "60px" }} placeholder='max' />
                </div>
            </div> */}
            < div >
                <strong>Filters</strong>
                <div className='mt-2'>
                    <Collapse items={sizeData} defaultActiveKey={['1']} onChange={onChange} />
                    <p></p>
                    <Collapse items={colorData} defaultActiveKey={['0']} onChange={onChange} />
                </div>
            </div >

        </div >
    )
}

export default Filter