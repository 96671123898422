import React from 'react'
import ProductGrid from '../../components/products/ProductGrid'
import Filter from '../../components/products/Filter' 
import { useParams } from 'react-router-dom';

function Products() {
  const params = useParams()

  console.log(params.id);

  window.scrollTo({
    top: 0,
    behavior: 'smooth' // Optional: Adds smooth scrolling animation
  });

  return (
    <div className='d-flex animation'>
      <div className='dddmm' style={{ padding: '25px', width: "300px" }}>
        <Filter />
      </div>
      <div className='w-100'>
        {/* <ProductGridSkeleton /> */}
        <div className='p-2'>
          <ProductGrid />
        </div>
      </div>
    </div >
  )
}

export default Products