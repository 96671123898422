import React, { useEffect } from 'react'
import './products.css'
import { Paper, Rating } from '@mui/material'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProducts } from '../../store/product/ProductSlice'


function ProductGrid() {

    const { products } = useSelector((state) => state?.product)
    const dispatch = useDispatch()


    console.log(products);

    useEffect(() => {
        dispatch(getAllProducts())
    }, [])

    return (
        <div className='product-grid mb-5'>
            {
                products.map((res, i) => (
                    <Paper elevation={0} key={i} style={{ maxWidth: "250px" }} >
                        <Link to={"/products/" + res._id} key={i} className='product-grid-item text-center'>
                            < img src={res?.finalVariantList[0]?.imageArray[0]?.url} alt='' className='productDisplayImage' />
                            <div style={{ fontWeight: "600", marginTop: "5px", fontSize: "12px" }} className='text-start text-center'>{res?.brand}</div>
                            <div
                                style={{
                                    fontSize: "12px",
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    fontSize: '12px',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '160px'
                                }} className='text-secondary text-center'>{res?.title}</div>
                            <small style={{ textAlign: 'center' }}>{res?.category}</small>
                            <div className='d-flex gap-2 justify-content-center'>
                                {/* <Rating name="read-only" value={5} readOnly /> */}
                                {/* <div>({res.rating.title})</div> */}
                            </div>
                            <div className='d-flex gap-2 mb-4'>
                                <strong> ₹ {parseFloat(res.finalVariantList[0].sizeArray[0].offerPrice).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>
                                <div className='text-secondary' style={{ textDecoration: "line-through" }}> ₹ {parseFloat(res.finalVariantList[0].sizeArray[0].mrp).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                <strong style={{ fontSize: "12px", color: "green", lineHeight: "20px" }}>{(100 - ((res.finalVariantList[0].sizeArray[0].offerPrice / res.finalVariantList[0].sizeArray[0].mrp)) * 100).toFixed(0)}% off</strong>
                            </div>
                        </Link>
                    </Paper>
                ))
            }
        </div >
    )
}

export default ProductGrid


