import React from 'react'
import onam from '../../images/Group 4.png'

function AdSectionThree() {
    return (
        <div>
            <img src={onam} className='w-100' style={{ marginBottom: "-16px" }} />
        </div>
    )
}

export default AdSectionThree