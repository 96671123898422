import { SIZE } from "../../const/ApiConst"
import { INSTANCE } from "../../const/Header"

export const getSize = async (sizeData) => {
    const response = await INSTANCE.get(`${SIZE}`, sizeData)
    return response.data
}

const sizeService = {
    getSize
}

export default sizeService