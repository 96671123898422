import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import newArrivalService from "./NewArrivalService"

const initialState = {
    newArrivalItems: [],
    isLoading: false,
    isError: false,
    isSuccess: false
}

export const getNewArrivals = createAsyncThunk(
    "get/newArrival",
    async (thunkAPI) => {
        try {
            return await newArrivalService.getNewArrivals()
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)



export const newArrivalSlice = createSlice({
    name: "newarrivals",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getNewArrivals.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getNewArrivals.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.newArrivalItems = action.payload
            })
            .addCase(getNewArrivals.rejected, (state) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })
    }
})
export default newArrivalSlice.reducer