import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cartService from "./CartService";

const initialState = {
    cart: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
}

export const createCartSlice = createAsyncThunk(
    'cart/post',
    async (cartData, thunkAPI) => {
        try {
            return await cartService.createCart(cartData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getCart = createAsyncThunk(
    'cart/get',
    async (thunkAPI) => {
        try {
            return await cartService.getCart()
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)



export const delteCartItem = createAsyncThunk(
    'cart/delete',
    async (cartData, thunkAPI) => {

        try {
            return await cartService.removeFromCart(cartData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);

export const clearCart = createAsyncThunk(
    'cart/clear',
    async (custID, thunkAPI) => {
        try {
            return await cartService.clearCart(custID)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createCartSlice.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.isSuccess = false;
            })
            .addCase(createCartSlice.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
            })
            .addCase(createCartSlice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.cart = action.payload; // Assuming the payload is the new cart data
            })
            .addCase(getCart.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getCart.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
            })
            .addCase(getCart.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.isSuccess = true
                state.cart = action.payload
            })
            // wwwwwww
            .addCase(delteCartItem.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(delteCartItem.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
            })
            .addCase(delteCartItem.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.isSuccess = true
                state.cart = action.payload
            })

            .addCase(clearCart.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(clearCart.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
            })
            .addCase(clearCart.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.isSuccess = true
                state.cart = action.payload
            })

    }
});



export default cartSlice.reducer;
