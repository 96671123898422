import { ADDRESS, GET_ADDRESS } from "../../const/ApiConst"
import { INSTANCE } from "../../const/Header"

export const getAddress = async (userData) => {

    const response = await INSTANCE.post(GET_ADDRESS, userData)

    return response.data

}

export const addAddress = async (addressData) => {
    const response = await INSTANCE.post(ADDRESS, addressData)
    return response.data
}

export const deleteAddress = async (addressData) => {
    const response = await INSTANCE.post(`${ADDRESS}delete`, addressData)
    return response.data
}

export const updateAddress = async (addressData) => {
    const response = await INSTANCE.post(`${ADDRESS}update`, addressData)
    return response.data
}



const addressService = {
    getAddress,
    addAddress,
    deleteAddress,
    updateAddress
}


export default addressService