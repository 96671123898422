import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import addressService from "./AddressService"


const initialState = {
    adresss: [],
    isLoading: false,
    isError: false,
    isSuccess: false
}

export const getAddress = createAsyncThunk(
    'get/addresss',
    async (userData, thunkAPI) => {
        try {
            return await addressService.getAddress(userData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const addAddress = createAsyncThunk(
    'add/addresss',
    async (addressData, thunkAPI) => {
        console.log(addressData);
        try {
            return await addressService.addAddress(addressData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const deleteAddress = createAsyncThunk(
    'delete/addresss',
    async (addressData, thunkAPI) => {
        try {
            return await addressService.deleteAddress(addressData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const updateAddress = createAsyncThunk(
    'update/addresss',
    async (addressData, thunkAPI) => {
        try {
            return await addressService.updateAddress(addressData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAddress.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getAddress.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.isSuccess = true
                state.adresss = action.payload
            })
            .addCase(getAddress.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
            })

            .addCase(addAddress.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(addAddress.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.isSuccess = true
                state.adresss = action.payload
            })
            .addCase(addAddress.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
            })

            .addCase(deleteAddress.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(deleteAddress.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.isSuccess = true
                state.adresss = action.payload
            })
            .addCase(deleteAddress.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
            })
            .addCase(updateAddress.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(updateAddress.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.isSuccess = true
                state.adresss = action.payload
            })
            .addCase(updateAddress.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
            })
    }
})

export default addressSlice.reducer