import React from 'react'
import { Col, Row } from 'react-bootstrap'
import onamImage from '../../images/onam.jpg'
import './OnamCollection.css'


// const text = "Step into the enchanting world of tradition and celebration with our exclusive Onam collection. Inspired by Kerala's vibrant celebrations and cultural heritage, our carefully curated selection brings you the essence of this joyous occasion. Capture the timeless elegance of Onam and indulge in the charm of intricately woven silk sarees embellished with exquisite golden motifs. This festival signifies purity"
const text = 'Embrace the Spirit of Onam with Our Exclusive Collection - A Celebration of Colors, Culture, and Tradition!" "Discover the Essence of Onam in Every Piece - Shop Our Exclusive Collection Today!"'

function OnamCollection() {
    return (
        <div className='container'>
            <Row rev>
                <Col xs={12} sm={6} md={4} lg={8} className='d-flex flex-column justify-content-center' >
                    <h1 className='text-end mb-2 pe-5'>Explore <br /> our exclusive Onam Collection</h1>
                    <div className='text-end w-100 mb-3 pt-5 pe-5' style={{ fontFamily: 'Poppins', fontWeight: '300' }}>{text}</div>
                    <div className='text-end   pe-5'>
                        <button className="btn btn-outline-secondary">Read More!</button>
                    </div>
                </Col>
                <Col xs={12} sm={6} md={4} lg={4} className='mt-4' >
                    <img className='img-fluid' src={onamImage} alt='Onam Collection' />

                </Col>
            </Row>

        </div>
    )
}

export default OnamCollection