import React, { useEffect, useState } from 'react'
import { HiOutlineMenuAlt1 } from 'react-icons/hi'
import { MdExpandMore } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getCategories } from '../../store/category/CategorySlice'
import { VscTriangleRight } from 'react-icons/vsc'

function HeaderMenu() {

    const [toggleCategorymenu, setToggleCategorymenu] = useState(false)


    const categoryContainer = {
        background: 'white',
        borderRadius: '10px 10px 0 0 ',
        padding: "15px",
        width: "260px",
        cursor: 'pointer',
        fontWeight: '600',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative'
    }

    const categoryMenuItems = {
        display: toggleCategorymenu === true ? "block" : "none",
        transition: '1s easeout'
    }

    const { categories, isLoading } = useSelector((state) => state?.categories)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCategories())
        // eslint-disable-next-line
    }, [])

    return (
        <div className='headerMenu'>
            <div className='container d-flex gap-5 align-items-center'>
                <div
                    style={categoryContainer}
                    onMouseEnter={() => setToggleCategorymenu(true)}
                    onMouseLeave={() => setToggleCategorymenu(false)}
                >
                    <div className='d-flex align-items-center gap-2'>
                        <HiOutlineMenuAlt1 style={{ textSize: 'bold', color: '#00ade9' }} /> <div style={{ color: '#00ade9' }}> All Categories</div>
                    </div>
                    <div><MdExpandMore /></div>
                    <div className='category-menu-items' style={categoryMenuItems}>
                        {isLoading ? (
                            <div className='d-flex justify-content-center mb-3'>
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <ul className='d-flex flex-column gap-3 mt-2' style={{fontSize:"13px"}}>
                                <li style={{ borderLeft: '3px solid #fff', paddingLeft: '5px' }}>
                                    <a href={"/products"} >
                                        All
                                    </a>
                                </li>
                                {categories.map((res, i) =>
                                    res.parentCategory === "Parent" ? (
                                        <li key={i} className='d-flex flex-column' style={{ borderLeft: '3px solid #fff', paddingLeft: '5px' }}>
                                            <a href={"/categories/" + res.categoryName}  >
                                                {res.categoryName} <VscTriangleRight />
                                            </a>
                                            {categories.map((item, i) =>
                                                item.parentCategory === res.categoryName ?
                                                    (<a key={i} href={"/categories/" + item.categoryName} className=' ms-2' style={{fontWeight:100}}>{item.categoryName}</a>)
                                                    : null
                                            )}


                                        </li>
                                    ) : null
                                )}

                            </ul>
                        )}
                        {/* <div className='sub-menu-nav-menu'>
                            SUB MENU
                        </div> */}


                    </div>
                </div>
                <Link to='/' style={{ fontWeight: '600', color: '#ffffff' }}>
                    Homepage
                </Link>
                <div style={{ fontWeight: '600', color: '#ffffff' }}>
                    Onam Specials
                </div>

            </div>
        </div >
    )
}

export default HeaderMenu