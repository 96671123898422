import React from 'react'
import { Col, Row } from 'react-bootstrap'
import menImage from '../../images/men-img.png'
import womenImage from '../../images/women-img.png'
import './Banner3.css'
import { Link } from 'react-router-dom'

function Banner3() {
    return (
        <div className='bg-white p-3'>
            {/* <h1 className='text-center mb-2'>Hand Picked</h1>
            <div className='text-center w-100 mb-3' style={{ fontFamily: 'Poppins', fontWeight: '300' }}> Embrace Your Allure with Our Touch of Coverage Collection</div> */}

            <div className='banner-3-container'>

                <Col>
                    <div className="product-image-container">
                        <img src={womenImage} className="w-100" alt='' />
                        <Link to="/categories/Women" className='shop-btn'>Shop for Women</Link>
                    </div>
                </Col>
                <Col>
                    <div className="product-image-container">
                        <img src={menImage} className="w-100" alt='' />
                        <Link to="/categories/Men" className='shop-btn'>Shop for Men</Link>
                    </div>
                </Col>


            </div>
        </div>
    )
}

export default Banner3