import { CATEGORY } from "../../const/ApiConst"
import { INSTANCE } from "../../const/Header"

export const getCategories = async (categoryData) => {
    const response = await INSTANCE.get(CATEGORY, categoryData)
    return response.data
}

const categoryService = {
    getCategories
}

export default categoryService