import React, { useEffect } from 'react'
import './cart.css'
import { useDispatch, useSelector } from 'react-redux'
import { createCartSlice, delteCartItem, getCart } from '../../store/cart/CartSlice'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { ImBin } from 'react-icons/im'
import { cartState } from '../../store/CloseCartState'
import Cookies from 'js-cookie'

const sampleImage = "https://mir-s3-cdn-cf.behance.net/projects/404/95974e121862329.Y3JvcCw5MjIsNzIxLDAsMTM5.png"

const pdtImage = {
    width: '80px',
    marginRight: '10px'
}

function Cart(props) {

    const { cart } = useSelector((state) => state?.cart)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getCart())
        // eslint-disable-next-line
    }, [])


    const saveCart = (a, b, c, d, e, f) => {
        const data = {
            qty: a,
            size: d,
            color: e,
            custId: b,
            productID: c,
            price: f
        }
        dispatch(createCartSlice(data))
        dispatch(getCart())
    }

    var subtotal = 0

    const removeItemFromCart = (e) => {
        const data = {
            _id: e,
            custId: JSON.parse(Cookies.get("udtl")).uid
        }
        dispatch(delteCartItem(data))

    }


    const handleChange = (event) => {
        // const newValue = event.target.value;
        // setChildValue(newValue);
        props.onValueChange(false); // Call the callback function from props
    };



    return (
        <div>
            {cart.length === 0 ? (
                <div className='p-3' style={{ background: "#f8f8f8" }}>

                    <div className='text-center mt-0 mb-4' style={{ fontSize: '20px', fontWeight: "bold" }}>Cart</div>

                    <img src={sampleImage} alt='' className='mt-5' style={{ width: "350px" }} />
                </div>
            ) : (
                <div className='p-3'>
                    <div className='text-center mt-0 mb-4' style={{ fontSize: '20px', fontWeight: "bold" }}>Cart</div>
                    {cart.map((res, i) => (
                        <div key={i} className='d-flex justify-content-between gap-2 m-2'>
                            <div className='d-flex'>
                                <div><img src={res.products[0]?.finalVariantList[0]?.imageArray[0].url} alt={res.title} style={pdtImage} /></div>
                                <div className='d-flex flex-column'>
                                    <strong>{res.products[0].title}</strong>
                                    {/* <small className='text-secondary'>{res.products[0].brand}</small> */}
                                    <small className='text-secondary'>₹ {res.price}
                                        {console.log(res)}
                                        <small >
                                            <div>Size : {res.size} </div>
                                            <div style={{ lineHeight: "1.1" }}>Color : {res.color}</div>
                                        </small>
                                        {/* res.products[0].offerPrice !== 0 ? (res.products[0].offerPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (res.products[0].price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") */}
                                    </small>
                                    <div className='d-flex align-items-center  gap-2' style={{ minWidth: "200px", marginTop: "5px" }}>
                                        <div style={{ fontSize: "14px" }}>Qty</div>
                                        <select onChange={(e) => saveCart(e.target.value, res.custId, res.productID, res.size, res.color, res.price)} style={{ padding: "0px", border: '1px solid #e0e0e0', borderRadius: '7px' }}>
                                            <option selected hidden>{res.qty}</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'none' }}> {(subtotal = subtotal + res.qty * res.price)}</div>
                            <div className='p-2' onClick={() => removeItemFromCart(res._id)}><ImBin style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} /></div>
                        </div>
                    ))}
                    <div>
                        <div
                            className='p-2'
                            style={{
                                display: 'grid',
                                gridTemplateColumns: "auto auto",
                            }}>
                            <div>Sub Total</div>
                            <div className='text-end'>₹ {subtotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            <div>Shipping & Delivery Charges</div>
                            <div className='text-end text-success'>Free</div>
                            <div><strong>Grand Total</strong></div>
                            <div className='text-end'><strong>₹ {(subtotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></div>
                        </div>
                       
                    </div>

                </div>
            )}
        </ div>
    )
}

export default Cart