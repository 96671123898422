import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import productService from "./ProductService"
import filterService from "../filter/FilterService"

const initialState = {
    relatedproducts: [],
    products: [],
    isLoading: false,
    isError: false,
    isSuccess: false
}

export const getAllProducts = createAsyncThunk(
    "get/product",
    async (productData, thunkAPI) => {
        try {
            return await productService.getAllProducts(productData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getSingleProduct = createAsyncThunk(
    'get/single-product',
    async (productData, thunkAPI) => {
        try {
            return await productService.getSingleProduct(productData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getRelatedProduct = createAsyncThunk(
    'get/related-product',
    async (productData, thunkAPI) => {
        try {
            return await productService.getRelatedProduct(productData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getFilteredService = createAsyncThunk(
    "get/filter",
    async (filterData, thunkAPI) => {
        try {
            return await filterService.getFilteredItems(filterData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)
export const getFilteredValueService = createAsyncThunk(
    "get/filter-by-value",
    async (filterData, thunkAPI) => {
        try {
            return await filterService.getFilteredValueItems(filterData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const productSlice = createSlice({
    name: "products",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllProducts.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getAllProducts.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.products = action.payload
            })
            .addCase(getAllProducts.rejected, (state) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })
            .addCase(getSingleProduct.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getSingleProduct.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.products = action.payload
            })
            .addCase(getSingleProduct.rejected, (state) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })
            .addCase(getRelatedProduct.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getRelatedProduct.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.relatedproducts = action.payload
            })
            .addCase(getRelatedProduct.rejected, (state) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })
            .addCase(getFilteredService.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getFilteredService.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.products = action.payload
            })
            .addCase(getFilteredService.rejected, (state) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })

            .addCase(getFilteredValueService.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getFilteredValueService.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.products = action.payload
            })
            .addCase(getFilteredValueService.rejected, (state) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })
    }
})

export default productSlice.reducer