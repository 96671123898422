import { PRODUCT } from "../../const/ApiConst"
import { INSTANCE } from "../../const/Header"

export const getNewArrivals = async (productData) => {
    const response = await INSTANCE.post(`${PRODUCT}/new-arrivals`, productData)
    return response.data
}

const newArrivalService = {
    getNewArrivals
}

export default newArrivalService