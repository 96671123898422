import React, { useEffect } from 'react'
import Filter from '../../components/products/Filter';
import ProductGridFilter from '../../components/products/ProductGridFilter';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFilteredService } from '../../store/product/ProductSlice';
import ProductGridSkeleton from '../../components/skeletons/ProductGridSkeleton';

function ProductsFilter() {
  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getFilteredService({ searchKey: params.id, limit: 8 }))
  }, [])

  window.scrollTo({
    top: 0,
    behavior: 'smooth' // Optional: Adds smooth scrolling animation
  });



  const { isLoading } = useSelector((state) => state?.product)



  const numOfTimesToPrint = 8;
  const componentsArray = [];

  for (let i = 0; i < numOfTimesToPrint; i++) {
    componentsArray.push(<ProductGridSkeleton key={i} />);
  }


  return (
    <div className='d-flex animation'>
      <div className='dddmm' style={{ padding: '25px', width: "300px" }}>
        <Filter />
      </div>
      <div className='w-100'>
        {/* <ProductGridSkeleton /> */}
        <div className='p-2'>
          {isLoading ? <div className='product-grid mb-5'>
            {componentsArray}
          </div> : <ProductGridFilter />}


        </div>
      </div>
    </div >
  )
}

export default ProductsFilter