import React, { useEffect } from 'react'
import Carousel from 'react-multi-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { getTrending } from '../../store/trending/TrendingSlice';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
};

function Trending({ deviceType }) {
    const autoPlay = deviceType !== 'mobile';
    const dispatch = useDispatch()


    const { trendingItems } = useSelector((state) => state?.trending)


    useEffect(() => {
        dispatch(getTrending())
    }, [])

    return (
        <div className='container'>
            <h1 className='text-center'>Trending</h1>

            <div className="text-center w-100 mb-3" style={{ fontFamily: 'Poppins', fontWeight: '300px' }}>  Trending MILY designers: Discover the latest!</div>

            <Carousel
                swipeable={false}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on the server-side.
                infinite={true}
                autoPlay={autoPlay}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition='all .5'
                transitionDuration={500}
                className='carousel-container'
                removeArrowOnDeviceType={['tablet', 'mobile']}
                deviceType={deviceType}
                dotListClass='custom-dot-list-style'
                itemClass='carousel-item-padding-40-px'
            >
                {trendingItems.map((res, i) => (
                    <div className='p-2' key={i}>
                        <img src={res.images[0]?.url} alt='musthave' className='w-100' style={{ height: "350px" }} />
                        <div
                            className=' mt-2 text-center text-secondary'
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: '400',
                                fontSize: '14px',
                            }}>{res.title}</div>
                        <div className='d-flex justify-content-around'>
                            <div style={{ fontSize: "18px", fontWeight: "600", color: '#18b5eb' }}> ₹ {res.finalVariantList[0].sizeArray[0].mrp}</div>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    )
}

export default Trending