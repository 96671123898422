import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { VscTriangleRight } from 'react-icons/vsc'
import { Divider, Input } from 'antd'



function SideMenuMobile() {

    const { categories, isLoading } = useSelector((state) => state?.categories)



    const categoryMenuMob = {
        listStyle: 'none',
        padding: '0',
        margin: '0',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        fontSize: "14px"
    }

    const categoryMenuItems = {
        // display: toggleCategorymenu === true ? "block" : "none",
        transition: '1s easeout',
        marginTop: "20px"
    }


    return (
        <div className='p-4' style={{width:"200px"}}>
            {/* <div><Input placeholder='Search...' /></div> */}
            
            <div className='mt-4'>
                <strong style={{ fontSize: "14px" }}>Categories</strong>
            </div>
            <Divider className='mt-2 mb-2' />

            <div className='category-menu-items' style={categoryMenuItems}>
                {isLoading ? (
                    <div className='d-flex justify-content-center mb-3'>
                        <div className="loader"></div>
                    </div>
                ) : (
                    <ul className='d-flex flex-column gap-3 mt-2' style={{ fontSize: "13px" }}>
                        <li style={{ borderLeft: '3px solid #fff', paddingLeft: '5px' }}>
                            <a href={"/products"} >
                                All
                            </a>
                        </li>
                        {categories.map((res, i) =>
                            res.parentCategory === "Parent" ? (
                                <li key={i} className='d-flex flex-column' style={{ borderLeft: '3px solid #fff', paddingLeft: '5px' }}>
                                    <a href={"/categories/" + res.categoryName}  >
                                        {res.categoryName} <VscTriangleRight />
                                    </a>
                                    {categories.map((item, i) =>
                                        item.parentCategory === res.categoryName ?
                                            (<a key={i} href={"/categories/" + item.categoryName} className=' ms-2' style={{ fontWeight: 100 }}>{item.categoryName}</a>)
                                            : null
                                    )}


                                </li>
                            ) : null
                        )}

                    </ul>
                )}
            </div>
        </div>
    )
}

export default SideMenuMobile