import React from 'react'
import kidImage from '../../images/kid-image.jpeg'
import sareeImage from '../../images/saree.jpg'
import fabric from '../../images/fabrics.jpg'
import menImage from '../../images/men.jpg'
import { Link } from 'react-router-dom'



function ShopNow() {
    return (
        <div>
            <h1 className="text-center mb-2">Shop Now</h1>
            <div className="text-center w-100 mb-3" style={{ fontFamily: 'Poppins', fontWeight: '300px' }}> Shop by top categories</div>
            <div className='shop-now-component-image-container mb-5' >
                {/* {
                    shopNow.map((res, i) => (
                        <div key={i} style={{ position: 'relative' }}>
                            <img src={res.image} className='shop-now-component-image' alt='' />
                            <div className='shop-now-component-text'>{res.title}</div>
                        </div>
                    ))
                } */}
                <Link to='categories/mens-wear' style={{ position: 'relative' }}>
                    <img src={menImage} className='shop-now-component-image' alt='' />
                    <div className='shop-now-component-text'>Men's Wear</div>
                </Link>
                <Link to="categories/Sarees" style={{ position: 'relative' }}>
                    <img src={sareeImage} className='shop-now-component-image' alt='' />
                    <div className='shop-now-component-text'>Sarees</div>
                </Link>
                <Link to="categories/Sarees" style={{ position: 'relative' }}>
                    <img src={fabric} className='shop-now-component-image' alt='' />
                    <div className='shop-now-component-text'>Fabrics</div>
                </Link>
                <Link to='categories/kids' style={{ position: 'relative' }}>
                    <img src={kidImage} className='shop-now-component-image' alt='' />
                    <div className='shop-now-component-text'>Kid's Wear</div>
                </Link>
            </div>
        </div >
    )
}

export default ShopNow