import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllOrders } from '../../store/orders/OrderSlice'
import Cookies from 'js-cookie' 
import { Button, Divider } from 'antd'
import './orders.css'


function Orders() {

    const dispatch = useDispatch()

    const { orderData } = useSelector((state) => state?.orders)

    console.log(orderData);

    useEffect(() => {
        dispatch(getAllOrders({ custId: JSON.parse(Cookies.get("udtl")).uid }))
    }, [])

    const imgStyle = {
        width: "100px"
    }

    const cancelOrderActio = () => {
        dispatch()
    }

    return (
        <div className='container p-4'>

            {orderData?.map((order, i) => (
                <div key={i}>
                    {order.items.map((item, j) => (
                        <div className='order-container bg-white' key={j}>
                            <div className='d-flex gap-3'>
                                <div><img src={item?.products[0]?.finalVariantList[0]?.imageArray[0]?.url} alt='' className='order-container-image' /></div>
                                <div>
                                    <div>
                                        <div>Product Name</div>
                                        <div className='d-flex order-subcontainer'>
                                            <small className='text-secondary'>Color: {item.color} | &nbsp;</small>
                                            <small className='text-secondary'> Size: {item.size} | &nbsp;</small>
                                            <small className='text-secondary'> Qty: {item.qty}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='order-subcontainer gap-5 '>
                                <strong>₹ {(item.qty * parseFloat(item.price)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>
                                <div>
                                    <strong>{order.status}</strong>
                                    {order.status === "Order Placed" ? (
                                        <div className='mt-2 text-center'><Button type='primary' style={{ background: 'red', color: "white" }}>Cancel</Button></div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}


        </div>
    )
}

export default Orders