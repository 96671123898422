import React, { useState } from 'react'
import { BsHandbag } from 'react-icons/bs'
import { CloseSquareFilled } from '@ant-design/icons';
import { HiOutlineMenuAlt1 } from 'react-icons/hi'
import Logo from '../../images/logo.png'
import { Link } from 'react-router-dom'
import { AutoComplete, Badge, Button, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCart } from '../../store/cart/CartSlice'
import Cart from '../cart/Cart'
import { SwipeableDrawer } from '@mui/material'
import SideMenuMobile from './SideMenuMobile'
import { AiOutlineClose } from 'react-icons/ai'

function MiddleHeader() {
    const [options, setOptions] = useState([]);

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };



    const asddsa = {
        height: "40px",
        // display: visible ? "none" : "block"
    }

    const { cart } = useSelector((state) => state?.cart)
    const { categories, isLoading } = useSelector((state) => state?.categories)
    const dispatch = useDispatch()



    useEffect(() => {
        dispatch(getCart())
        // eslint-disable-next-line
    }, [])
    const mockVal = (str, repeat = 1) => ({
        value: str.repeat(repeat),
    });
    const getPanelValue = (searchText) => !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)];


    const handleValueChange = (newValue) => {
        toggleDrawer('right', false)

    };





    return (
        <div className='container d-flex justify-content-between align-items-center mt-3 mb-3'>
            <div className='mid-header-menu-icon'>
                <HiOutlineMenuAlt1 style={{ fontSize: "25px" }} onClick={toggleDrawer('left', true)} />
                <React.Fragment>
                    <SwipeableDrawer
                        anchor={'left'}
                        open={state['left']}
                        onClose={toggleDrawer('left', false)}
                        onOpen={toggleDrawer('left', true)}
                    >
                        <SideMenuMobile />
                    </SwipeableDrawer>
                </React.Fragment>
            </div>
            <div><Link to={'/'}><img src={Logo} alt='logo' style={asddsa} /></Link></div>
            {/* <div className='serarchContainer-header'>

                <AutoComplete
                    options={options}
                    style={{
                        width: 200,
                        border: 'none',
                        minWidth: '200px',
                        marginRight: '5px'
                    }}
                    onSearch={(text) => setOptions(getPanelValue(text))}
                    placeholder="Product Search"
                    allowClear={{
                        clearIcon: <CloseSquareFilled />,
                    }}
                />

                <Select
                    placeholder="Select Category"
                    style={{
                        width: 200,
                        border: 'none',
                        minWidth: '200px',
                        marginRight: '5px'
                    }}
                    // onChange={handleChange}
                    options={[
                        {
                            value: 'jack',
                            label: 'Jack',
                        },
                        {
                            value: 'lucy',
                            label: 'Lucy',
                        },
                        {
                            value: 'Yiminghe',
                            label: 'yiminghe',
                        },
                        {
                            value: 'disabled',
                            label: 'Disabled',
                            disabled: true,
                        },
                    ]}
                />


                <div style={{ borderRight: '1px solid #e6e6e6' }} />
                <button style={{ height: "100%", color: "white", background: "#00ade9", border: 'none', fontWeight: 'bold', padding: '10px 20px' }} >Search</button>
            </div> */}
            <div className='d-flex align-items-center gap-4' style={{ fontSize: "20px" }}>
                <Badge
                    count={cart?.length}
                    style={{
                        background: '#00ade9',
                    }}>
                    <BsHandbag onClick={toggleDrawer('right', true)} style={{ cursor: 'pointer', fontSize: "20px" }} />
                    <React.Fragment>
                        <SwipeableDrawer
                            anchor={'right'}
                            open={state['right']}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div onClick={toggleDrawer('right', false)}><AiOutlineClose className='ms-4 mt-4' style={{ cursor: 'pointer', color: 'red', fontSize: '25px' }} /></div>
                            <Cart />
                            {cart.length > 0 ? (
                                <div className='ps-5 pe-5 pt-2'>
                                    <Link to='/checkout'><Button className='w-100' onClick={toggleDrawer('right', false)}>Check out</Button></Link>
                                </div>
                            ) : null}
                        </SwipeableDrawer>
                    </React.Fragment>
                </Badge>
                {/* <FaRegUser /> */}
            </div>
        </div >
    )
}

export default MiddleHeader