const BACKEND_BASE_URL = process.env.REACT_APP_BASE_URL

export const CATEGORY = `${BACKEND_BASE_URL}/api/category`
export const PRODUCT = `${BACKEND_BASE_URL}/api/product`
export const GET_SINGLE_PRODUCT = `${BACKEND_BASE_URL}/api/product/single-product`

export const SIZE = `${BACKEND_BASE_URL}/api/size`

export const CART = `${BACKEND_BASE_URL}/api/cart`

export const SIGNUP_WITH_EMAIL = `${BACKEND_BASE_URL}/api/customers/signup-with-email`
export const SIGNUP_WITH_GOOGLE = `${BACKEND_BASE_URL}/api/customers/signup-with-google`

export const PLACE_ORDER = `${BACKEND_BASE_URL}/api/orders/`

export const GET_ADDRESS = `${BACKEND_BASE_URL}/api/address/get-address/`
export const ADDRESS = `${BACKEND_BASE_URL}/api/address/`


export const ORDERS = `${BACKEND_BASE_URL}/api/orders/`