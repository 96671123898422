import React from 'react'
import TopperPanel from './TopperPanel'
import './header.css'
import HeaderMenu from './HeaderMenu'
import MiddleHeader from './MiddleHeader'

function Header() {
    return (
        <div>
            <div style={{ position: 'fixed', top: 0, zIndex: 999, width: "100%", background: "white" }}>
                <TopperPanel />
                <MiddleHeader />
                <HeaderMenu />
            </div>
        </div>
    )
}

export default Header