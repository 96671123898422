import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Avatar, Box, Divider, Menu, Rating, Tab, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { getRelatedProduct, getSingleProduct } from '../../store/product/ProductSlice'
import { Button, ColorPicker, Image, Select } from 'antd'
import { createCartSlice, getCart } from '../../store/cart/CartSlice'
import Cookies from 'js-cookie'
import { loginState } from '../../store/loginState'
import SimpleImageSlider from "react-simple-image-slider";
import { cartState } from '../../store/CloseCartState'
import './product.css'
import { getCategories } from '../../store/category/CategorySlice'
import { BsInfoCircle } from 'react-icons/bs'
import { IoMdShareAlt } from 'react-icons/io'
import { FaEnvelope } from 'react-icons/fa'
import { WhatsappShareButton, FacebookMessengerShareButton, EmailShareButton } from 'react-share';
import ProductShareButton from '../../components/productShare/ProductShareButton'


const qtyList = [
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
    { value: 6 }
]


function ProductView() {



    var finalPrice = 0;
    var custId = undefined;
    if (Cookies.get("udtl") !== undefined) {
        custId = JSON.parse(Cookies.get("udtl")).uid
    }




    const params = useParams()
    // Store
    const dispatch = useDispatch()

    const { products, isSuccess } = useSelector((state) => state?.product)
    const { categories } = useSelector((state) => state?.categories)
    const { relatedproducts } = useSelector((state) => state?.product)

    // States



    const [selectedColor, setSelectedColor] = useState(products[0]?.finalVariantList[0]?.imageArray[0]?.asset_id.toString())
    const [selectedSize, setselectedSize] = useState(products[0]?.finalVariantList[0]?.sizeArray[0]?.size)
    const [mobImagePreview, setMobImagePreview] = useState([])
    const [displayPrice, setDisplayPrice] = useState(0)
    const [displayMrp, setDisplayMrp] = useState(0)
    const [value, setValue] = React.useState('1');
    const [firstImage, setFirstImage] = useState(products[0]?.finalVariantList[0]?.imageArray[0].url)
    const [qty, setQty] = useState(1)
    const [color, setColor] = useState(products[0]?.finalVariantList[0]?.colorTitle)
    const [size, setSize] = useState(products[0]?.finalVariantList[0]?.sizeArray[0]?.size)
    const [colorImage, setColorImage] = useState(products[0]?.finalVariantList[0]?.imageArray[0].url)

    setTimeout(() => {
        setSelectedColor(products[0]?.finalVariantList[0]?.imageArray[0]?.asset_id.toString())
        setselectedSize(products[0]?.finalVariantList[0]?.sizeArray[0]?.size)
    }, 100);

    // Use Ref
    const productUrl = window.location.href


    // Actions
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const setSizeAction = (data) => {
        setDisplayPrice(data?.res?.offerPrice)
        setDisplayMrp(data?.res?.mrp)
        // setSize(data?.res?.size)

    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const saveCart = () => {


        if (custId === undefined) {
            dispatch(loginState(true))
        } else {
            const data = {
                qty,
                size,
                color,
                colorImage: "colorImage",
                custId,
                productID: products[0]?._id,
                price: displayPrice
            }
            console.log(data);
            dispatch(createCartSlice(data))
            dispatch(cartState(true))
        }
    }


    // Useeffects
    useEffect(() => {
        const data = []
        products[0]?.finalVariantList.map((res, i) => {
            res.imageArray.map((rea, a) => {
                data.push({
                    url: res.url
                })
            })
        })
        setMobImagePreview(...mobImagePreview, data)
    }, [])



    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: Adds smooth scrolling animation
        });


        dispatch(getSingleProduct(params.id))
        dispatch(getRelatedProduct({ categoryName: params.id }))
        dispatch(getCategories())
    }, [])

    useEffect(() => {
        if (isSuccess) {
            setDisplayMrp(products[0]?.finalVariantList[0]?.sizeArray[0]?.mrp)
            setDisplayPrice(products[0]?.finalVariantList[0]?.sizeArray[0]?.offerPrice)
        }
    }, [isSuccess])

    // Styles

    const dummyImageList = {
        width: '100%',
        height: '150px',
        objectFit: "cover"
    }

    const dummayImage = {
        width: '100%',
        cursor: 'pointer',
        objectFit: "cover"
    }

    return (
        <div className='container pt-4' style={{ position: 'relative' }}>
            <div

                className='d-flex align-items-center gap-2 text-secondary'
                style={{
                    position: 'absolute',
                    top: "30px",
                    right: "20px",
                    borderRadius: "5px",
                    padding: "5px",
                    background: "white",
                    zIndex: 99,
                    // boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    cursor: "pointer",
                    fontSize: '12px',
                }}>

                <ProductShareButton productUrl={productUrl} />
                <IoMdShareAlt />
            </div>
            <div className='mobrow bg-white p-3 mb-3'>
                <div className='mobrow mobrow-hidden w-50xx'>
                    <div className=' col-2 d-flex flex-column gap-2 dsdsds'>
                        {products[0]?.finalVariantList.map((res, i) => (
                            res.imageArray.map((rea, a) => (
                                <div style={dummyImageList} key={i}>
                                    <img

                                        src={rea.url} alt=''
                                        onClick={() => setFirstImage(res.url)}
                                        style={dummyImageList} />
                                </div>

                            ))
                        )
                        )}
                    </div>
                    <div>
                        {/* <div
                            className='image-zoom-container'
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onMouseMove={handleMouseMove}
                        >
                            <div className='image-zoom' style={calculateZoomStyle()}>
                                <img
                                    src={products[0]?.finalVariantList[0]?.imageArray[0].url}
                                    className='image-zoom-img'
                                />
                            </div>
                        </div> */}
                        <div style={dummayImage} >
                            <Image src={products[0]?.finalVariantList[0]?.imageArray[0].url} style={dummayImage} alt='' />
                        </div>
                    </div>
                </div>
                <div className='mobrow-showm' style={{ position: 'relative' }}>
                    <Image src={products[0]?.finalVariantList[0]?.imageArray[0].url} style={dummayImage} alt='' />
                    {/*                   
                    <SimpleImageSlider
                        width='100%'
                        height={400}
                        images={mobImagePreview}
                        showBullets={true}
                        showNavs={true}
                    /> */}
                </div>
                <div className='w-50xx d-flex flex-column gap-2'>
                    <div className='text-secondary'>
                        {categories?.map((ress, s) =>
                            ress?.categoryName === products[0]?.category ?
                                (<small style={{ cursor: 'pointer' }}>{ress.parentCategory !== "Parent" ? (<Link to={"/categories/" + ress.parentCategory}>{ress.parentCategory} &gt; </Link>) : null} <Link to={"/categories/" + products[0].category}>{products[0].category}</Link></small>)
                                : null
                        )}

                    </div>

                    <div >
                        <div className='text-secondary'><strong>{products[0]?.brand}</strong></div>
                        <strong style={{ fontSize: '25px' }}>{products[0]?.title}</strong>
                    </div>
                    {/* <Rating name="read-only" value={4} readOnly /> */}
                    <div className='d-flex align-items-center gap-1'>

                        <div className='d-flex gap-2'>
                            <strong style={{ fontSize: '20px' }}>
                                <div>₹ {parseFloat(displayPrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            </strong>
                            <div className='text-secondary d-flex align-items-center gap-2' >
                                <div style={{ fontSize: '20px', textDecoration: 'line-through' }}>
                                    ₹ {parseFloat(displayMrp).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                                <div style={{ fontSize: "15px", color: '#00a54a' }}  >
                                    {(100 - (displayPrice / displayMrp) * 100).toFixed(0)}% off
                                </div>
                            </div>
                        </div>

                        <BsInfoCircle onClick={handleClick} style={{ cursor: "pointer" }} />
                    </div>
                    <Divider />
                    <strong>Colors</strong>
                    <div className='d-flex gap-3'>
                        {products[0]?.finalVariantList.map((res, i) => (
                            <Tooltip title={res.colorTitle} key={i}>
                                {res?.imageArray.map((reb, b) => (
                                    <div
                                        tabindex="0"
                                        key={b} >
                                        <img
                                            src={reb.url}
                                            style={{
                                                border: selectedColor === reb.asset_id.toString() ? "2px solid #00ade9" : "",
                                                cursor: 'pointer',
                                                height: '75px',
                                                height: '75px',
                                                objectFit: "cover"
                                            }}
                                            onClick={() => {
                                                setColorImage(res.url)
                                                setSelectedColor(reb.asset_id)
                                            }}
                                        />
                                    </div>
                                ))}
                            </Tooltip>
                        ))}
                    </div>

                    <strong>Sizes:</strong>
                    <div className='d-flex gap-2' style={{ overflowX: 'auto' }}>
                        {
                            products[0]?.finalVariantList.map((rex, u) =>
                                rex.sizeArray.map((res, i) => (
                                    <div
                                        tabIndex="0"
                                        className='pdt-size-text'
                                        style={{
                                            outline: "none",
                                            border: selectedSize === res.size ? '2px solid #00ade9' : '1px solid black',
                                            margin: '10px',
                                            padding: '5px 10px',
                                            cursor: 'pointer',
                                        }}
                                        key={i}
                                        onClick={() => {
                                            setselectedSize(res.size)
                                            setSizeAction({ res })
                                        }}
                                    >
                                        {res.size}
                                    </div>
                                ))
                            )
                        }
                    </div>

                    <div className='d-flex  alilgn-items-center gap-4'>
                        <Select
                            style={{ width: "100px" }}
                            defaultValue={1}
                            options={qtyList}
                            onChange={(e) => setQty(e)}
                        />
                        <Button type="primary" danger onClick={() => saveCart()}>Add to cart</Button>
                    </div>

                    <div className='mt-4'>
                        {/* <Button type='primary' className='w-100'>Buy Now</Button> */}
                    </div>
                    <Divider className='mt-2' />
                    < TabContext value={value} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Product Description" value="1" />
                                <Tab label="Additional information" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <div style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: products[0]?.description }}></div>
                        </TabPanel>
                        <TabPanel value="2">
                            <div dangerouslySetInnerHTML={{ __html: products[0]?.additional_info }}></div>
                        </TabPanel>
                    </TabContext >
                </div>
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <div className='d-flex flex-column gap-3 p-3'>
                    <strong>Price Details</strong>
                    <div className='d-flex justify-content-between'>
                        <small className='text-secondary'><strong>Maximum Retail Price</strong></small>
                        <strong>₹ {parseFloat(displayMrp).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <small className='text-secondary'><strong>Selling Price</strong></small>
                        <strong>₹ {parseFloat(displayPrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>
                    </div>
                    <Divider />
                    <small style={{ color: "#00a54a" }}>Overall you have saved ₹ {(displayMrp - displayPrice).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ({(100 - ((displayPrice / displayMrp) * 100).toFixed(0))}%) on this product </small>
                </div>
            </Menu>


            {/* Rating Section */}
            {/* <div className='bg-white p-3 mb-3'>
                <strong>User Reviews</strong>
                <div className='m-2 d-flex flex-column gap-3'>
                    <div className='d-flex gap-3'>
                        <Avatar>A</Avatar>
                        <div className='d-flex flex-column'>
                            <strong style={{ fontSize: "15px" }}>Amal P Nair</strong>
                            <Rating name="read-only" value={5} readOnly />
                            <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </small>
                        </div>
                    </div>
                    <div className='d-flex gap-3'>
                        <Avatar>A</Avatar>
                        <div className='d-flex flex-column'>
                            <strong>Amal P Nair</strong>
                            <Rating name="read-only" value={5} readOnly />
                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                        </div>
                    </div>
                    <div className='d-flex gap-3'>
                        <Avatar>A</Avatar>
                        <div className='d-flex flex-column'>
                            <strong>Amal P Nair</strong>
                            <Rating name="read-only" value={4} readOnly />
                            <div>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='mb-5 bg-white p-2'>
                <strong>Related Products</strong>
                <div className='d-flex justify-content-between' style={{ overflow: 'auto' }}>
                    {relatedproducts.map((rrr, r) => (
                        <a href={"/products/" + rrr._id} key={r} className='text-center m-2' style={{ width: "200px", cursor: 'pointer' }}>
                            <img src={rrr?.finalVariantList[0]?.imageArray[0]?.url} style={{ width: "200px" }} />
                            <div className='text-center'><small>{rrr.title}</small></div>
                            <div className='d-flex align-items-center gap-2'>
                                <strong>₹ {rrr?.finalVariantList[0]?.sizeArray[0]?.offerPrice}</strong>
                                <div className='text-secondary' style={{ textDecoration: "line-through" }}>₹ {rrr?.finalVariantList[0]?.sizeArray[0]?.mrp}</div>
                                <span style={{ fontSize: "12px", color: 'green', fontWeight: "600" }}>{100 - ((rrr?.finalVariantList[0]?.sizeArray[0]?.offerPrice / rrr?.finalVariantList[0]?.sizeArray[0]?.mrp) * 100).toFixed(0)}% Off</span>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div >



    )
}

export default ProductView



