import React from 'react'

function Support() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    return (
        <div className="container d-flex flex-column align-items-center mb-4 pe-5 ps-5 animation">
            <h1 className="pt-0 text-center">SUPPORT</h1>
            <p className='text-center'>If you have any inquiries concerning products or orders, kindly contact us on +91-83-300-500-84 or email us at support@milyonline.in <br />Also, you can contact through our mail system given below.</p>
            <form style={{ width: '400px', }} className='p-5 d-flex flex-column gap-3'>

                <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input type="text" className="form-control" id="name" name="name" required />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" className="form-control" id="email" name="email" required />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone Number:</label>
                    <input type="tel" className="form-control" id="phone" name="phone" required />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message:</label>
                    <textarea className="form-control" id="message" name="message" required></textarea>
                </div>
                <button type="submit" className="btn w-100 mt-4 btn-primary">Submit</button>
            </form>
        </div>
    )
}

export default Support