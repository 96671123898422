import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';
import { WhatsappShareButton, FacebookMessengerShareButton, EmailShareButton } from 'react-share';

function ProductShareButton({ productUrl }) {
  const shareMessage = `Check out this awesome product: `;

  return (
    <div>
      <WhatsappShareButton url={productUrl} title={shareMessage}>
        <BsWhatsapp />
         &nbsp; Share via WhatsApp
      </WhatsappShareButton>

      {/* <FacebookMessengerShareButton url={productUrl} appId="YOUR_FACEBOOK_APP_ID">
        <FaEnvelope />
        Share via Facebook Messenger
      </FacebookMessengerShareButton>

      <EmailShareButton url={productUrl} subject="Check out this product">
        <FaEnvelope />
        Share via Email
      </EmailShareButton> */}
    </div>
  );
}

export default ProductShareButton;
