import React, { useEffect, useRef } from 'react'
import './products.css'
import { Paper } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'


function ProductGridFilter() {

    const { products } = useSelector((state) => state?.product)


    return (
        <div className='product-grid mb-5'  >
            {
                products.length !== 0 ?
                    products?.map((res, i) => (
                        <Paper elevation={0} key={i} style={{ maxWidth: "250px" }} >
                            <Link to={"/products/" + res._id} key={i} className='product-grid-item text-center'>
                                < img src={res?.finalVariantList[0]?.imageArray[0]?.url} alt='' className='productDisplayImage' />
                                <div style={{ fontWeight: "600", marginTop: "5px", fontSize: "12px" }} className='text-start text-center'>{res?.brand}</div>
                                <div
                                    style={{
                                        fontSize: "12px",
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        fontSize: '12px',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '160px'
                                    }} className='text-secondary text-center'>{res?.title}</div>
                                <small style={{ textAlign: 'center' }}>{res?.category}</small>
                                {/* <div className='d-flex gap-2 justify-content-center'>
                                <Rating name="read-only" value={res.rating.title} readOnly />
                                <div>({res.rating.title})</div>
                            </div> */}
                                {/* <strong> ₹ {res?.finalVariantList?.sizeArray[0]?.mrp}</strong> */}

                                <div className='d-flex gap-2 mb-4'>
                                    <strong> ₹ {parseFloat(res.finalVariantList[0].sizeArray[0].offerPrice).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>
                                    <div className='text-secondary' style={{ textDecoration: "line-through" }}> ₹ {parseFloat(res.finalVariantList[0].sizeArray[0].mrp).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                    <strong style={{ fontSize: "12px", color: "green", lineHeight: "20px" }}>{(100 - ((res.finalVariantList[0].sizeArray[0].offerPrice / res.finalVariantList[0].sizeArray[0].mrp)) * 100).toFixed(0)}% off</strong>
                                </div>
                            </Link>
                        </Paper>
                    ))
                    : (<div className='mt-5'>
                        <div>No Product Found!</div>
                    </div>)
            }
        </div >
    )
}

export default ProductGridFilter