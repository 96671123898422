import { PRODUCT } from "../../const/ApiConst"
import { INSTANCE } from "../../const/Header"

export const getFeatured = async (productData) => {
    const response = await INSTANCE.post(`${PRODUCT}/featured`, productData)
    return response.data
}

const featuredService = {
    getFeatured
}

export default featuredService