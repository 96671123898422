import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import './carousel.css'
import banner1 from '../../images/banner-1.png'
import banner2 from '../../images/banner-2.png'
import banner3 from '../../images/banner-3.png'
import banner4 from '../../images/banner-4.png'
import banner5 from '../../images/banner-5.png'
// import bannersm1 from '../../images/banner-sm-1.png'
import bannersm2 from '../../images/banner-sm-2.png'
import bannersm3 from '../../images/banner-sm-3.png'
import bannersm4 from '../../images/banner-sm-4.png'
import bannersm5 from '../../images/banner-sm-5.png'

const images = [
    {
        title: 'Summer Sale: Up to 50% Off',
        text: 'Save big on summer essentials like swimsuits, sandals, and sunglasses.',
        imgPath: banner1,
    },
    {
        title: 'New Arrivals: Shop the Latest Trends',
        text: 'Check out our latest arrivals from your favorite brands.',
        imgPath: banner2
    },
    // {
    //     title: 'Back to School: Everything You Need for a Successful Year',
    //     text: 'Get ready for the new school year with our selection of backpacks, notebooks, and school supplies',
    //     imgPath: banner3
    // },

];
const mobileimages = [
    // {
    //     title: 'Summer Sale: Up to 50% Off',
    //     text: 'Save big on summer essentials like swimsuits, sandals, and sunglasses.',
    //     imgPath: bannersm1,
    // },
    {
        title: 'New Arrivals: Shop the Latest Trends',
        text: 'Check out our latest arrivals from your favorite brands.',
        imgPath: bannersm2
    },
    {
        title: 'Back to School: Everything You Need for a Successful Year',
        text: 'Get ready for the new school year with our selection of backpacks, notebooks, and school supplies',
        imgPath: bannersm3
    },
    {
        title: 'Black Friday Deals: Save Up to 75%',
        text: "Don't miss out on our biggest sale of the year!",
        imgPath: bannersm4
    },
    {
        title: 'Cyber Monday Sales: Save Big on Electronics',
        text: 'Get the best deals on electronics for Cyber Monday.',
        imgPath: bannersm5
    }
];

var iimmgg = 0;
const imagesCount = images.length

function Carousel1() {


    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentImageIndex((prevIndex) =>
    //             prevIndex < images.length - 1 ? prevIndex + 1 : 0
    //         );
    //     }, 2000);

    //     return () => clearInterval(interval);
    // }, [images.length]);
    const [visible, setVisible] = useState(true);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setVisible(false); // Fade out the current image
            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length); // Move to the next image index
                setVisible(true); // Fade in the next image after a short delay
            }, 1000);
        }, 2000);

        return () => clearInterval(interval);
    }, []);


    return (
        <div >
            {/* <img src={images[currentImageIndex].imgPath} alt={`Slide ${currentImageIndex + 1}`} /> */}
            {/* {visible && (
                <img
                    src={images[currentImageIndex].imgPath}
                    alt="Image"
                    className="fade-in-out"
                />
            )} */}
            {/* {carousel()} */}
            <Carousel className='web-carousel'> 
                {
                    images.map((res, i) => (
                        <Carousel.Item key={i}>
                            <img
                                className="d-block w-100 carousel-image"
                                src={res.imgPath}
                                alt="First slide"
                            />
                        </Carousel.Item>
                    ))
                }

            </Carousel>
            {/* <Carousel className='mobile-carousel'> */}
            {/* <Carousel className='web-carousel'>
                {
                    mobileimages.map((res, i) => (
                        <Carousel.Item key={i}>
                            <img
                                className="d-block w-100 carousel-image"
                                src={res.imgPath}
                                alt="First slide"
                            />
                        </Carousel.Item>
                    ))
                }

            </Carousel> */}
        </div>
    )
}

export default Carousel1