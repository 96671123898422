import React from 'react'
import adOne from '../../images/Saree Banner.png'

function AdSectionOne() {
    return (
        <div className='container'>
            <img src={adOne} className='w-100' />
        </div>
    )
}

export default AdSectionOne