import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import categoryService from "./CategoryService"

const initialState = {
    categories: [],
    isLoading: false,
    isError: false,
    isSuccess: false
}

export const getCategories = createAsyncThunk(
    'get/categories',
    async (categoryData, thunkAPI) => {
        try {
            return await categoryService.getCategories(categoryData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const categorySlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCategories.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.isSuccess = true
                state.categories = action.payload
            })
            .addCase(getCategories.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
            })
    }
})

export default categorySlice.reducer