import { PRODUCT } from "../../const/ApiConst"
import { INSTANCE } from "../../const/Header"

export const getFilteredItems = async (productData) => {
    const response = await INSTANCE.post(`${PRODUCT}/filter`, productData)
    return response.data
}


export const getFilteredValueItems = async (productData) => {
    const response = await INSTANCE.post(`${PRODUCT}/filter-by-value`, productData)
    return response.data
}




const filterService = {
    getFilteredItems,
    getFilteredValueItems
}

export default filterService