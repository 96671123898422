import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import filterService from "./FilterService"

const initialState = {
    filteredItems: [],
    isLoading: false,
    isError: false,
    isSuccess: false
}

export const getFilteredService = createAsyncThunk(
    "get/filter",
    async (filterData, thunkAPI) => {
        try {
            return await filterService.getFilteredItems(filterData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)



export const filterSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFilteredService.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getFilteredService.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.filteredItems = action.payload
            })
            .addCase(getFilteredService.rejected, (state) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })
    }
})
export default filterSlice.reducer