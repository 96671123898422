import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { addAddress, deleteAddress, getAddress } from '../../store/address/AddressSlice';
import Cookies from 'js-cookie';
import { Box, Tab, Tabs, Typography, colors } from '@mui/material';
import { Button, Divider, Empty, Input } from 'antd';
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useState } from 'react';

const schema = yup.object().shape({
    name: yup.string().required('Name Required'),
    contactno: yup.string().matches(/^\d{10}$/, 'Mobile number must be 10 digits').required('Mobile no Required'),
    flat: yup.string().required('Required'),
    area: yup.string().required('Required'),
    landmark: yup.string().required('Required'),
    town: yup.string().required('Required'),
    pincode: yup.string().required('Required,'),
    state: yup.string().required('Required')
})

function AccountSettings() {
    const [value, setValue] = React.useState(0);
    const fName = JSON.parse(Cookies.get("udtl")).fullName
    const mobNo = JSON.parse(Cookies.get("udtl")).phoneNumber
    const uemail = JSON.parse(Cookies.get("udtl")).email

    const [fullName, setFullName] = useState(fName)
    const [contactNo, setContactNo] = useState(mobNo)
    const [email, setEmail] = useState(uemail)


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addressContainer = {
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        padding: "15px",
        position: "relative",
        width: '250px'
    }


    const dispatch = useDispatch()


    const { adresss } = useSelector((state) => state?.address)


    const data = {
        custId: JSON.parse(Cookies.get("udtl")).uid
    }

    useEffect(() => {
        dispatch(getAddress(data))
    }, [])

    const formik = useFormik({
        initialValues: {
            cust_id: "",
            name: fullName,
            contactno: "",
            flat: "",
            area: "",
            landmark: "",
            town: "",
            pincode: "",
            state: "",
        },
        validationSchema: schema,
        onSubmit: (values) => {
            values.cust_id = JSON.parse(Cookies.get("udtl")).uid
            dispatch(addAddress(values))
            formik.resetForm()
        }
    })

    const addressContainerContainer = {
        display: 'flex',
        flexWrap: "wrap",
        gap: '15px'
    }




    const deleteAddressAction = (id) => {
        const data1 = {
            addsrId: id,
            custId: JSON.parse(Cookies.get("udtl")).uid
        }
        dispatch(deleteAddress(data1))
    }




    return (
        <div className='container animation pt-3 mb-4'>
            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="bg-white m-1 p-2">
                <Tabs
                    // orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    <Tab label="Address" />
                    <Tab label="Basic Info" />
                    {/* Add more tabs as needed */}
                </Tabs>

                {/* Tab Panel */}
                <Box sx={{ p: 2, width: "100%" }}>
                    {value === 1 && <Typography>
                        <div>
                            <Form className='d-flex flex-column gap-3 w-100'>
                                <Input value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder='Full Name' className='w-100' />
                                <Input value={contactNo} onChange={(e) => setContactNo(e.target.value)} placeholder='Contact No' className='w-100' />
                                <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' className='w-100' />
                                <Button type='primary' className='w-100'>Update</Button>
                            </Form>
                        </div>
                    </Typography>}
                    {value === 0 && <Typography>
                        <div>
                            <strong>Address Details</strong>
                            <div style={addressContainerContainer}>
                                {adresss.length === 0 ? (<Empty />) : (
                                    adresss.map((res, i) => (
                                        <div style={addressContainer}>
                                            <AiOutlineCloseCircle style={{ position: "absolute", top: '5px', right: "5px", color: 'red',fontSize:"20px",cursor:"pointer" }} onClick={() => deleteAddressAction(res._id)} />
                                            <div><strong>{res.name}</strong>,</div>
                                            <div>{res.contactno},</div>
                                            <div>{res.flat},</div>
                                            <div>{res.area},</div>
                                            <div>{res.landmark},</div>
                                            <div>{res.town},</div>
                                            <div>{res.pincode},</div>
                                            <div>{res.state},</div>
                                        </div>
                                    ))
                                )}

                            </div>
                            <Divider />
                            <Form onSubmit={formik.handleSubmit} className='d-flex flex-column gap-1 w-100 mt-4'>
                                <strong>New Address</strong>
                                <p></p>
                                <div>
                                    <Input
                                        placeholder='Name'
                                        onChange={formik.handleChange('name')}
                                        onBlur={formik.handleBlur('name')}
                                        value={formik.values.name}
                                    />
                                    <div className='form-error'>
                                        {formik.touched.name && formik.errors.name}
                                    </div>
                                </div>
                                <div>
                                    <Input
                                        placeholder='Contact No'
                                        onChange={formik.handleChange('contactno')}
                                        onBlur={formik.handleBlur('contactno')}
                                        value={formik.values.contactno}
                                    />
                                    <div className='form-error'>
                                        {formik.touched.contactno && formik.errors.contactno}
                                    </div>
                                </div>
                                <div>
                                    <Input
                                        placeholder='Flat/House/Apartment'
                                        onChange={formik.handleChange('flat')}
                                        onBlur={formik.handleBlur('flat')}
                                        value={formik.values.flat}
                                    />
                                    <div className='form-error'>
                                        {formik.touched.flat && formik.errors.flat}
                                    </div>
                                </div>
                                <div>
                                    <Input
                                        placeholder='Street / Area'
                                        onChange={formik.handleChange('area')}
                                        onBlur={formik.handleBlur('area')}
                                        value={formik.values.area}
                                    />
                                    <div className='form-error'>
                                        {formik.touched.area && formik.errors.area}
                                    </div>
                                </div>
                                <div>
                                    <Input
                                        placeholder='Landmark'
                                        onChange={formik.handleChange('landmark')}
                                        onBlur={formik.handleBlur('landmark')}
                                        value={formik.values.landmark}
                                    />
                                    <div className='form-error'>
                                        {formik.touched.landmark && formik.errors.landmark}
                                    </div>
                                </div>
                                <div>
                                    <Input
                                        placeholder='Town/City'
                                        onChange={formik.handleChange('town')}
                                        onBlur={formik.handleBlur('town')}
                                        value={formik.values.town}
                                    />
                                    <div className='form-error'>
                                        {formik.touched.town && formik.errors.town}
                                    </div>
                                </div>
                                <div>
                                    <Input
                                        placeholder='PIN Code'
                                        onChange={formik.handleChange('pincode')}
                                        onBlur={formik.handleBlur('pincode')}
                                        value={formik.values.pincode}
                                    />
                                    <div className='form-error'>
                                        {formik.touched.pincode && formik.errors.pincode}
                                    </div>
                                </div>
                                <div>
                                    <Input
                                        placeholder='State'
                                        onChange={formik.handleChange('state')}
                                        onBlur={formik.handleBlur('state')}
                                        value={formik.values.state}
                                    />
                                    <div className='form-error'>
                                        {formik.touched.state && formik.errors.state}
                                    </div>
                                </div>
                                <Button type='primary' style={{ background: "#00ade5" }} onClick={formik.handleSubmit}>Save</Button>
                            </Form>
                        </div>
                    </Typography>}
                    {/* Add more tab panels as needed */}
                </Box>
            </Box>
        </div>
    )
}

export default AccountSettings