import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import Products from './pages/product/Products';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import ProductView from './pages/product/ProductView';
import Checkout from './pages/checkout/Checkout';
import PaymentSuccess from './pages/payment_success/PaymentSuccess';
import Support from './pages/support/Support';
import Shippingdelivery from './pages/shippingdelivery/Shippingdelivery';
import ReturnandExchange from './pages/returnandexchange/ReturnandExchange';
import Faq from './pages/faq/faq';
import TermsandConditions from './pages/termsandconditions/TermsandConditions';
import Privacyandpolicy from './pages/privacyandpolicy/Privacyandpolicy';
import AccountSettings from './pages/accountsettings/AccountSettings';
import ProductsFilter from './pages/product/ProductsFilter';
import { Fab } from '@mui/material';
import { BsWhatsapp } from 'react-icons/bs'
import axios from 'axios';
import Orders from './pages/orders/Orders';


function generateWhatsAppUrl(phoneNumber, message) {
  const baseUrl = 'https://api.whatsapp.com/send';
  const encodedMessage = encodeURIComponent(message);
  return `${baseUrl}?phone=${phoneNumber}&text=${encodedMessage}`;
}


function App() {
  // window.scrollTo({
  //   top: 0,
  //   behavior: 'smooth' // Optional: Adds smooth scrolling animation
  // });

  const phoneNumber = '+918330050084'; // Replace with the desired WhatsApp number
  const message = 'Hello!'; // Replace with your message

  const handleWhatsAppClick = () => {
    const whatsappUrl = generateWhatsAppUrl(phoneNumber, message);
    window.open(whatsappUrl, '_blank');
  };


  return (
    <div style={{ position: "relative" }}>
      <Header />
      <div className='body-margin'>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<Home />} />
          <Route path='/products' element={<Products />} />
          <Route path='/products/:id' element={<ProductView />} />
          <Route path='/categories/:id' element={<ProductsFilter />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/success' element={<PaymentSuccess />} />
          <Route path='/support' element={<Support />} />
          <Route path='/shipping&delivery' element={<Shippingdelivery />} />
          <Route path='/returns&exchange' element={<ReturnandExchange />} />
          <Route path='/faqs' element={<Faq />} />
          <Route path='/terms&conditions' element={<TermsandConditions />} />
          <Route path='/privacy-policy' element={<Privacyandpolicy />} />
          <Route path='/account-settings' element={<AccountSettings />} />
          <Route path='/orders' element={<Orders />} />
        </Routes>
      </div>
      <Fab
        color="success"
        aria-label="edit"
        style={{
          position: 'fixed',
          bottom: "20px",
          right: "20px"
        }}
        onClick={handleWhatsAppClick}
      >
        <BsWhatsapp />
      </Fab>
      <Footer />
    </div>
  );
}

export default App;
