import { GET_SINGLE_PRODUCT, PRODUCT } from "../../const/ApiConst"
import { INSTANCE } from "../../const/Header"

export const getAllProducts = async (productData) => {
    const response = await INSTANCE.get(PRODUCT, productData)
    return response.data
}


export const getSingleProduct = async (productData) => {
    const response = await INSTANCE.post(GET_SINGLE_PRODUCT, { slug: productData })
    return response.data
}

export const getRelatedProduct = async (productData) => {
    const response = await INSTANCE.post(`${PRODUCT}/related-product`, productData)
    return response.data
}

const productService = {
    getAllProducts,
    getSingleProduct,
    getRelatedProduct
}


export default productService