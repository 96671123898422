import { Box, Button, Divider, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { FcGoogle } from 'react-icons/fc'
import { HiOutlineMail } from 'react-icons/hi';
import { IoCall } from 'react-icons/io5';

import { createUserWithEmailAndPassword, getRedirectResult, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, sendPasswordResetEmail, updatePassword, getAuth, updateProfile } from 'firebase/auth'
import { auth, provider } from '../../const/FirebaseConfig';
import Cookies from 'js-cookie';
import LoggedInUserComponent from './LoggedInUserComponent';
import { INSTANCE } from '../../const/Header';
import { SIGNUP_WITH_EMAIL, SIGNUP_WITH_GOOGLE } from '../../const/ApiConst';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { loginState } from '../../store/loginState';

import './header.css'



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "20px"
};



function TopperPanel() {

  const dispatch = useDispatch()

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    dispatch(loginState(false))
    setOpen(false)
  };

  const [userDetails, setUserDetails] = useState(Cookies.get('udtl') || null)


  const [formType, setFormType] = useState("login")

  const [responseErrorone, setResponseErrorone] = useState("")
  const [authEmailLogin, setAuthEmailLogin] = useState("")
  const [authPasswordLogin, setAuthPasswordLogin] = useState("")
  const [loginLoading, setLoginLoading] = useState(false)



  const loginFormDesign = () => {
    return (
      <div className='d-flex flex-column gap-3' >
        <h4 align="center">Login</h4>
        <Form.Control onChange={(e) => setAuthEmailLogin(e.target.value)} />
        <Form.Control type='password' onChange={(e) => setAuthPasswordLogin(e.target.value)} />
        <div className='d-flex justify-content-between'>
          <div><input type="checkbox" checked /> Remember Me</div>
          <div style={{ cursor: "pointer" }} onClick={() => setFormType("register")}>Forgot Password?</div>
        </div>
        <div className='text-danger'>{responseErrorone}</div>
        <LoadingButton loading={loginLoading} variant='contained' className='w-100' onClick={() => loginAction()}>Login</LoadingButton>
        <Divider />
        <div style={{
          display: "flex",
          justifyContent: "space-around"
        }}>
          <FcGoogle onClick={() => signInWithGoogle()} style={{ fontSize: "30px", }} />
          {/* <ImFacebook style={{ fontSize: "30px", color: "#0076e7" }} /> */}
          {/* <IoLogoTwitter style={{ fontSize: "30px", color: "#009de9" }} /> */}
          {/* <FaApple style={{ fontSize: "30px", }} /> */}
        </div>
        <div className='d-flex justify-content-center'>
          <div>Don't have account?</div>
          <div className='text-primary' style={{ cursor: 'pointer' }} onClick={() => setFormType("sign-up")}>&nbsp; create new acount</div>
        </div>
      </div>
    )
  }


  const loginAction = async () => {
    setLoginLoading(true)
    try {
      await signInWithEmailAndPassword(auth, authEmailLogin, authPasswordLogin)
        .then(response => {
          setLoginLoading(false)
          sessionStorage.setItem('photoURL', "No Image")
          sessionStorage.setItem('udtl', JSON.stringify(response))
          const data = {
            uid: response.user.uid,
            fullName: response.user.displayName,
            email: response.user.email,
            phoneNumber: response.user.phoneNumber,
            userImage: response.user.photoURL
          }
          Cookies.set('photoURL', "No Image", { expires: 7 });
          Cookies.set('udtl', JSON.stringify(data), { expires: 7 });
          window.location.reload()
        }).catch(err => {
          setLoginLoading(false)
          if (err.message === "Firebase: Error (auth/user-not-found)") {
            setResponseErrorone("User Not found")
          } else {
            setResponseErrorone("Username or password are not matching")
          }
        })
    } catch (error) {
      setLoginLoading(false)
      console.log(error.error);
    }
  }


  // Password Reset
  const [forgotPasswordEmailInput, setForgotPasswordEmailInput] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState("")
  const resetPasswordAction = async () => {
    await sendPasswordResetEmail(auth, forgotPasswordEmailInput)
      .then(() => {
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        if (error.message === "Firebase: Error (auth/user-not-found).") {
          setResetPasswordError("This email id not registered with us")
        }
      });
  }


  const [fullName, setFullName] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [firstPassword, setFirstPassword] = useState("")
  const [isLoadingAction, setIsLoadingAction] = useState(false)
  const [responseError, setAuthError] = useState("")

  const signupFormDesign = () => {
    return (
      <div>
        <div className='d-flex flex-column gap-3'>
          <h4 align="center">Sign Up</h4>
          <Form.Control placeholder='Full Name' onChange={(e) => setFullName(e.target.value)} />
          <Form.Control placeholder='Email' onChange={(e) => setAuthEmail(e.target.value)} />
          <Form.Control placeholder='Contact No' onChange={(e) => setContactNumber(e.target.value)} />
          <Form.Control placeholder='Password' type='password' onChange={(e) => setFirstPassword(e.target.value)} />
          <Form.Control placeholder='Confirm Password' onChange={(e) => setAuthpassword(e.target.value)} />
          <div className='text-danger'>{responseError}</div>
          <LoadingButton loading={isLoadingAction} variant='contained' className='w-100' onClick={() => signUpAction()}>Register</LoadingButton>
          <Divider />
          <div style={{
            display: "flex",
            justifyContent: "space-around"
          }}>

          </div>
          <div className='d-flex justify-content-center'>
            <div>Already have account?</div>
            <div className='text-primary' style={{ cursor: 'pointer' }} onClick={() => setFormType("login")}>&nbsp; Login</div>
          </div>
        </div>
      </div>
    )
  }

  const forgotPasswordFormDesign = () => {
    return (
      <div>
        <div className='d-flex flex-column gap-3'>
          <h4 align="center">Forgot Passwrod</h4>
          <div className='text-danger'>{resetPasswordError}</div>
          <Form.Control onChange={(e) => setForgotPasswordEmailInput(e.target.value)} placeholder='Email' />
          <Button variant='contained' className='w-100' onClick={() => resetPasswordAction()}>Send reset link</Button>
          <Divider />
          <div style={{
            display: "flex",
            justifyContent: "space-around"
          }}>

          </div>
          <div className='d-flex justify-content-center'>
            <div>Already have account?</div>
            <div className='text-primary' style={{ cursor: 'pointer' }} onClick={() => setFormType("login")}>&nbsp; Login</div>
          </div>
        </div>
      </div>
    )
  }


  const [authEmail, setAuthEmail] = useState('')
  const [authpassword, setAuthpassword] = useState('')



  const signUpAction = async () => {
    setIsLoadingAction(true)
    if (firstPassword !== authpassword) {
      setAuthError("Password not matching")
      setIsLoadingAction(false)
    } else {
      try {
        await createUserWithEmailAndPassword(auth, authEmail, authpassword)
          .then(result => {
            sessionStorage.setItem('photoURL', "No Image")
            sessionStorage.setItem("attknres", result.user.accessToken);

            const data = {
              uid: result.user.uid,
              fullName: fullName,
              email: result.user.email,
              phoneNumber: contactNumber,
            }
            Cookies.set('udtl', JSON.stringify(data), { expires: 7 });

            const authx = getAuth();
            updateProfile(authx.currentUser, {
              displayName: fullName
            }).then(() => {
              // Profile updated!
              // ...
            }).catch((error) => {
              // An error occurred
              // ...
            });

            INSTANCE.post(
              SIGNUP_WITH_EMAIL,
              data
            )
              .then(response => {
                Cookies.set('udtl', JSON.stringify(data), { expires: 7 });
                Cookies.set('photoURL', "No Image");
                // sessionStorage.setItem('udtl', )
                window.location.reload()
                // Navigate('/Review_Booking', { state: TripData })
              })
          })
          .catch(err => {
            setIsLoadingAction(false)
            switch (err.message) {
              case 'Firebase: Error (auth/missing-password).':
                setAuthError('Password cannot be empty')
                break;
              case 'Firebase: Error (auth/invalid-email).':
                setAuthError('Invalid Email')
                break;
              case 'Firebase: Password should be at least 6 characters (auth/weak-password).':
                setAuthError('Password should be at least 6 characters.')
                break;
              case 'Firebase: Error (auth/email-already-in-use).':
                setAuthError('Email already in use.')
                break;
              default:
                break;
            }
          })
      } catch (error) {
        console.log(error);
        setIsLoadingAction(false)
      }
    }
  }

  const [signInStatus, setSignInStatus] = useState('');
  console.log(signInStatus);


  useEffect(() => {
    // Check if there is a redirect result after the user signs in.
    getRedirectResult(auth)
      .then((result) => {
        if (result.user) {
          // setSignInStatus(`Successfully signed in as ${result.user.email}`);
          const data = {
            uid: result.user.uid,
            fullName: result.user.displayName,
            email: result.user.email,
            phoneNumber: result.user.phoneNumber,
            userImage: result.user.photoURL
          }

          INSTANCE.post(
            SIGNUP_WITH_GOOGLE,
            data
          ).then(result => {
            // console.log(result); 
            window.location.reload()
          })
          Cookies.set('photoURL', result.user.photoURL, { expires: 7 });
          Cookies.set('udtl', JSON.stringify(data), { expires: 7 });
          setUserDetails(JSON.stringify(data))
          handleClose()
        }
      })
      .catch((error) => {
        setSignInStatus('Sign-in failed.');
        console.error(error);
      });
  }, []);

  const signInWithGoogle = () => {

    signInWithRedirect(auth, provider)

      .catch((error) => {
        setSignInStatus('Sign-in failed.');
        console.error(error);
      });
    // signInWithPopup(auth, provider)
    //   .then(result => {
    //     const data = {
    //       uid: result.user.uid,
    //       fullName: result.user.displayName,
    //       email: result.user.email,
    //       phoneNumber: result.user.phoneNumber,
    //       userImage: result.user.photoURL
    //     }

    //     INSTANCE.post(
    //       SIGNUP_WITH_GOOGLE,
    //       data
    //     ).then(result => {
    //       // console.log(result); 
    //       window.location.reload()
    //     })
    //     Cookies.set('photoURL', result.user.photoURL, { expires: 7 });
    //     Cookies.set('udtl', JSON.stringify(data), { expires: 7 });
    //     setUserDetails(JSON.stringify(data))
    //     handleClose()
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   })
  }


  const loginStatus = useSelector((state) => state?.loginFormReducer?.showLoginForm)

  useEffect(() => {
    if (loginStatus === true) {
      handleOpen()
    }
  }, [loginStatus])

  const loginSection = () => {
    return <div className='d-flex gap-4 mt-2 mb-2 '>

      <div onClick={handleOpen} style={{ cursor: 'pointer', }}>Login</div>
      <div style={{ cursor: "pointer" }}>Track your order</div>
    </div >
  }

  const loggedinSection = () => {
    return <div>
      <LoggedInUserComponent obj={JSON.parse(userDetails)} />
    </div>
  }





  return (
    <div style={{ borderBottom: '1px solid #e6e6e6' }}>
      <div className='container'>
        <div className='topper-panel'>
          <div className='topper-contact-setcion'>
            <div style={{ width: "100%" }}><a href="tel:+918330050084" style={{ fontSize: "14px" }}><IoCall /> +91-83-300-500-84 </a></div>
            <div style={{ width: "100%" }}><a className='d-flex align-items-center gap-1' href="mailto:support@milyonline.in"><HiOutlineMail /> <div>support@milyonline.in</div></a></div>
          </div>

          <div className='mob-contact'>
            <div style={{ width: "100%" }}><a href="tel:+918330050084" style={{ fontSize: "14px" }}><IoCall /> </a></div>
            <div style={{ width: "100%" }}><a className='d-flex align-items-center gap-1' href="mailto:support@milyonline.in"><HiOutlineMail /> </a></div>
          </div>

          <div className='topper-panel-right'>
            {
              userDetails === null ? loginSection() : loggedinSection()
            }
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              {formType === "login" ? loginFormDesign() : formType === 'sign-up' ? signupFormDesign() : formType === 'register' ? forgotPasswordFormDesign() : null}
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default TopperPanel
