import React from 'react'
import Skeleton1 from '@mui/material/Skeleton';
import { Skeleton } from 'antd'; 


function ProductGridSkeleton() {
    
    return (
        <div className='bg-white p-2'>
            <Skeleton.Image active={true} style={{ width: '250px', height: "350px" }} />
            <Skeleton1 variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton1 variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton1 variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton1 variant="text" sx={{ fontSize: '1rem' }} />
 
        </div>
    )
}

export default ProductGridSkeleton