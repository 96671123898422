
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app); 
export const auth = getAuth(app)
export const provider = new GoogleAuthProvider()
export const signInWithGoogle = () => {

    signInWithPopup(auth, provider).then((result) => {
        sessionStorage.setItem('email', result.user.email)
        sessionStorage.setItem('displayName', result.user.displayName)
        sessionStorage.setItem('photoURL', result.user.photoURL)

    }).catch((error) => {
        console.log(error)
    })
}

