import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import orderService from "./OrderService"

const initialState = {
    orderData: [],
    isLoading: false,
    isError: false,
    isSuccess: false
}

export const getAllOrders = createAsyncThunk(
    "get/orders",
    async (custID, thunkAPI) => {
        try {
            return await orderService.getAllOrders(custID)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getSingleOrder = createAsyncThunk(
    "get/single-order",
    async (orderID, thunkAPI) => {
        try {
            return await orderService.getSingleOrder(orderID)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)


export const orderSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrders.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getAllOrders.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.orderData = action.payload
            })
            .addCase(getAllOrders.rejected, (state) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })
            .addCase(getSingleOrder.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getSingleOrder.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.orderData = action.payload
            })
            .addCase(getSingleOrder.rejected, (state) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })
    }

})

export default orderSlice.reducer