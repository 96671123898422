import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import sizeService from "./SizeService"

const initialState = {
    size: [],
    isLoading: false,
    isError: false,
    isSuccess: false
}

export const getSize = createAsyncThunk(
    "get/size",
    async (filterData, thunkAPI) => {
        try {
            return await sizeService.getSize(filterData)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)



export const sizeSlice = createSlice({
    name: "size",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSize.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getSize.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.size = action.payload
            })
            .addCase(getSize.rejected, (state) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })
    }
})
export default sizeSlice.reducer