import React from 'react'
import './paymentSuccess.css'
import { Link } from 'react-router-dom'
import successImage from '../../images/success-icon.png'
import { Button } from '@mui/material'

function PaymentSuccess() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    return (
        <div className='container d-flex msuccessPage'>
            <div className='w-100 mb-4 mt-4'>
                <div className='text-center gap-2'>
                    <img src={successImage} alt='' className='msuccessImg' style={{ height: "200px",marginBottom:"20px" }} />
                    <h1 className='msuccessTitle'>Thank you for your purchase</h1>
                </div>

                <div className='mt-2 mb-2 text-center'>We email you an order confirmation with details and  tracking info</div>
                <div className='text-center'>
                    <Link to='/'>
                        <Button variant='contained'>Continue Shopping</Button>
                    </Link>
                </div>


            </div>


        </div>
    )
}

export default PaymentSuccess